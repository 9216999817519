export const DEFAULT_COLOR_PALETTE = [
  [26, 188, 156, 255],
  [46, 204, 113, 255],
  [52, 152, 219, 255],
  [155, 89, 182, 255],
  [52, 73, 94, 255],
  [22, 160, 133, 255],
  [39, 174, 96, 255],
  [41, 128, 185, 255],
  [142, 68, 173, 255],
  [44, 62, 80, 255],
  [241, 196, 15, 255],
  [230, 126, 34, 255],
  [231, 76, 60, 255],
  [236, 240, 241, 255],
  [149, 165, 166, 255],
  [243, 156, 18, 255],
  [211, 84, 0, 255],
  [192, 57, 43, 255],
  [189, 195, 199, 255],
  [127, 140, 141, 255]
]

export const NES_COLOR_PALETTE = [
  [0, 0, 0, 255],
  [252, 252, 252, 255],
  [248, 248, 248, 255],
  [188, 188, 188, 255],
  [124, 124, 124, 255],
  [164, 228, 252, 255],
  [60, 188, 252, 255],
  [0, 120, 248, 255],
  [0, 0, 252, 255],
  [184, 184, 248, 255],
  [104, 136, 252, 255],
  [0, 88, 248, 255],
  [0, 0, 188, 255],
  [216, 184, 248, 255],
  [152, 120, 248, 255],
  [104, 68, 252, 255],
  [68, 40, 188, 255],
  [248, 184, 248, 255],
  [248, 120, 248, 255],
  [216, 0, 204, 255],
  [148, 0, 132, 255],
  [248, 164, 192, 255],
  [248, 88, 152, 255],
  [228, 0, 88, 255],
  [168, 0, 32, 255],
  [240, 208, 176, 255],
  [248, 120, 88, 255],
  [248, 56, 0, 255],
  [168, 16, 0, 255],
  [252, 224, 168, 255],
  [252, 160, 68, 255],
  [228, 92, 16, 255],
  [136, 20, 0, 255],
  [248, 216, 120, 255],
  [248, 184, 0, 255],
  [172, 124, 0, 255],
  [80, 48, 0, 255],
  [216, 248, 120, 255],
  [184, 248, 24, 255],
  [0, 184, 0, 255],
  [0, 120, 0, 255],
  [184, 248, 184, 255],
  [88, 216, 84, 255],
  [0, 168, 0, 255],
  [0, 104, 0, 255],
  [184, 248, 216, 255],
  [88, 248, 152, 255],
  [0, 168, 68, 255],
  [0, 88, 0, 255],
  [0, 252, 252, 255],
  [0, 232, 216, 255],
  [0, 136, 136, 255],
  [0, 64, 88, 255],
  [248, 216, 248, 255],
  [120, 120, 120, 255]
]