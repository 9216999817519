import { commitSelectionBuffer, resetLasso } from './components/canvas'
import { APP, VIEW } from './state'
import { cloneImageData, deepCloneLayers } from './utils'

export const undo = () => {
  if (VIEW.undoPos + 1 === VIEW.undo.length) return

  VIEW.undoPos += 1
  VIEW.undo[VIEW.undoPos].undo()

  VIEW.render()
}

export const redo = () => {
  if (VIEW.undoPos - 1 === -2) return

  if (VIEW.undo[VIEW.undoPos].redo) VIEW.undo[VIEW.undoPos].redo()
  VIEW.undoPos -= 1

  VIEW.render()
}

export const addToUndo = (action, type = '') => {
  VIEW.undo.splice(0, VIEW.undoPos + 1, {
    icon: `${action}.svg`,
    action: `${action} ${type}`,
    undo: undo,
    redo: redo
  })

  if (VIEW.undoPos >= 0) VIEW.undoPos = -1  
  if (VIEW.undo.length > 50) VIEW.undo.pop()

  VIEW.currUndoRef = VIEW.undo[VIEW.undoPos + 1]
}

const saveAppState = () => {
  const prevLayers = deepCloneLayers(APP.layers)
  return {
  // layers: JSON.parse(JSON.stringify(APP.layers)),
    layers: deepCloneLayers(prevLayers),
    clipActive: APP.clipActive,
    frameActive: APP.frameActive,
    layerActive: APP.layerActive,
    frameCount: APP.frameCount,
    layerCount: APP.layerCount,
    selectionImgData: cloneImageData(VIEW.canvasSelection.imgData),
    lassoImgData: cloneImageData(VIEW.canvasLasso.imgData),
  }
};

const restoreAppState = (prevState) => {
  APP.frameCount = prevState.frameCount;
  APP.layerCount = prevState.layerCount;
  APP.frameActive = prevState.frameActive;
  APP.layerActive = prevState.layerActive;
  APP.clipActive = prevState.clipActive;
  APP.layers = prevState.layers;
  VIEW.canvasSelection.imgData.data.set(prevState.selectionImgData.data);
  VIEW.canvasLasso.imgData.data.set(prevState.lassoImgData.data);
  VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, 0, 0);
  VIEW.canvasLasso.ctx.putImageData(VIEW.canvasLasso.imgData, 0, 0);
};

const prepSelectionFreeze = (type) => {
  if (type === 'undo') {
    commitSelectionBuffer();
    resetLasso();
  }
}

export const freezeFrameData = (action, type, index) => {
  const prevState = saveAppState();
  prepSelectionFreeze(type)

  let clonedImageData;
  if (
    (action === 'delete' && type === 'undo') ||
    (action === 'new' && type === 'redo')
  ) {
    if (index > 0 && APP.drawings[index]) {
      clonedImageData = cloneImageData(APP.drawings[index]);
    }
  }

  VIEW.currUndoRef[type] = () => {
    restoreAppState(prevState);
    
    if (action === 'new' && type === 'undo' && index > 0) {
      // console.log('undo', index, APP.drawings, APP.drawings[index], clonedImageData)
      APP.drawings.splice(index, 1); // Remove image data
    }

    if (action === 'new' && type === 'redo' && index > 0) {
      // console.log('redo', index, APP.drawings, APP.drawings[index], clonedImageData)
      APP.drawings.splice(index, 0, clonedImageData); // Restore newd index
    }

    if (action === 'delete' && type === 'undo' && index > 0) {
      APP.drawings.splice(index, 0, clonedImageData); // Restore deleted index
    }

    if (action === 'delete' && type === 'redo' && index > 0) {
      APP.drawings.splice(index, 1); // Remove image data
    }

    VIEW.render();
  };
};

export const freezeLayerData = (type) => {
  const prevState = saveAppState();
  prepSelectionFreeze(type)

  VIEW.currUndoRef[type] = () => {
    restoreAppState(prevState);
    VIEW.render()
  };
};



