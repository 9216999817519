import { h } from 'preact'
import { APP, VIEW } from '../state'
import { Color } from './color'
import { commitSelectionBuffer, resetLasso } from './canvas'

export const setTool = (tool) => {
  APP.tool = tool
  VIEW.render()
}

const toggleLasso = () => {
  commitSelectionBuffer()
  resetLasso()
  VIEW.lasso.active = !VIEW.lasso.active
  VIEW.render()
}

export const Toolbar = () => {
  return <div class='w-40 bg-light bord-dark-r relative fl fl-column'>
    <div class='fl-1 overflow hide-scroll' style="padding-bottom: 50px;">
      {
        ['pencil', 'eraser', 'line', 'circle', 'square', 'fill', 'eye-dropper', 'move'].map(tool => 
          <button
            title={tool.charAt(0).toUpperCase() + tool.slice(1)}
            onClick={() => { setTool(tool) }}
            class="fl fl-center m-0 p-0 w-40 h-40 bord-dark-r"
            style={`${APP.tool === tool ? 'background: rgba(52, 152, 219, 255);' : ''}`}>
            <img src={`img/${tool}.svg`} />
          </button>
        )
      }
      <button
        title="Freeform Lasso"
        onClick={() => { toggleLasso() }}
        class="fl fl-center m-0 p-0 w-40 h-40 bord-dark-r"
        style={`${VIEW.lasso.active ? 'background: rgba(52, 152, 219, 255);' : ''}`}>
        <img src={`img/lasso.svg`} />
      </button>
      <button onClick={() => { VIEW.color.open = !VIEW.color.open; VIEW.render(); }} class='w-40 h-40 relative show-at-850'>
        <div class='w-full no-ptr' style={{ borderRadius: '4px', height: '30px', background: `rgb(${APP.color.rgb[0]},${APP.color.rgb[1]},${APP.color.rgb[2]})` }}></div>
      </button>
      {/* <button
        id="clear-data"
        class='w-40 h-40 relative'
        onClick={() => {
          localforage.clear().then(function() {
            console.log('All data cleared');
          }).catch(function(err) {
            console.log(err);
          });
        }}
      >X</button> */}
    </div>
    <div class="bord-dark-t w-full bg-light" style="position: absolute; bottom: 0;">
      <a class='show-at-850 w-full' target="_blank" href="https://www.youtube.com/@spritepaintapp" style={{ padding: '8px'}}>
        <div class='fl fl-center'>
          <img style="width: 20px;" src="img/youtube.svg" />
        </div>
      </a>
    </div>
    {VIEW.color.open && <div class='bg-dark' style={{ position: 'absolute', top:'60px', left: '100%', zIndex: '100', width: '250px' }}>
      <Color />
    </div>}
  </div>
}

