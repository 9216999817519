import { completeLasso } from './components/timeline';
import { NES_COLOR_PALETTE, DEFAULT_COLOR_PALETTE } from './constants/palettes';
import { addToUndo } from './undo-redo';
import { clamp, RGBtoHSL } from './utils'

export const resizeCanvas = () => {
  const newWidth = clamp(VIEW.resizeCanvas.width, 2, 500);
  const newHeight = clamp(VIEW.resizeCanvas.height, 2, 500);
  const anchor = VIEW.resizeCanvas.anchor || 'center';
  const scaleContent = VIEW.resizeCanvas.scale || false;

  completeLasso(); // Ensure any active selection is completed before resizing

  const oldWidth = APP.width;
  const oldHeight = APP.height;
  const oldDrawings = APP.drawings.map(drawing =>
    drawing ? new ImageData(new Uint8ClampedArray(drawing.data), drawing.width, drawing.height) : null
  );

  addToUndo('Resize', 'Canvas');
  VIEW.currUndoRef.undo = () => {
    APP.width = oldWidth;
    APP.height = oldHeight;
    APP.drawings = oldDrawings;
    resetCanvases(oldWidth, oldHeight, anchor, false);
    VIEW.render();
  };

  // Calculate scaling factors
  const scaleX = newWidth / oldWidth;
  const scaleY = newHeight / oldHeight;
  const scale = scaleContent ? Math.min(scaleX, scaleY) : 1;

  const scaledWidth = Math.floor(oldWidth * scale);
  const scaledHeight = Math.floor(oldHeight * scale);

  // Calculate offsetX & offsetY based on anchor
  let offsetX = 0, offsetY = 0;

  if (!scaleContent) {
    switch (anchor) {
      case "top-left": offsetX = 0; offsetY = 0; break;
      case "top-center": offsetX = Math.floor((newWidth - oldWidth) / 2); offsetY = 0; break;
      case "top-right": offsetX = newWidth - oldWidth; offsetY = 0; break;
      case "middle-left": offsetX = 0; offsetY = Math.floor((newHeight - oldHeight) / 2); break;
      case "center": offsetX = Math.floor((newWidth - oldWidth) / 2); offsetY = Math.floor((newHeight - oldHeight) / 2); break;
      case "middle-right": offsetX = newWidth - oldWidth; offsetY = Math.floor((newHeight - oldHeight) / 2); break;
      case "bottom-left": offsetX = 0; offsetY = newHeight - oldHeight; break;
      case "bottom-center": offsetX = Math.floor((newWidth - oldWidth) / 2); offsetY = newHeight - oldHeight; break;
      case "bottom-right": offsetX = newWidth - oldWidth; offsetY = newHeight - oldHeight; break;
    }
  } else {
    switch (anchor) {
      case "top-left": offsetX = 0; offsetY = 0; break;
      case "top-center": offsetX = Math.floor((newWidth - scaledWidth) / 2); offsetY = 0; break;
      case "top-right": offsetX = newWidth - scaledWidth; offsetY = 0; break;
      case "middle-left": offsetX = 0; offsetY = Math.floor((newHeight - scaledHeight) / 2); break;
      case "center": offsetX = Math.floor((newWidth - scaledWidth) / 2); offsetY = Math.floor((newHeight - scaledHeight) / 2); break;
      case "middle-right": offsetX = newWidth - scaledWidth; offsetY = Math.floor((newHeight - scaledHeight) / 2); break;
      case "bottom-left": offsetX = 0; offsetY = newHeight - scaledHeight; break;
      case "bottom-center": offsetX = Math.floor((newWidth - scaledWidth) / 2); offsetY = newHeight - scaledHeight; break;
      case "bottom-right": offsetX = newWidth - scaledWidth; offsetY = newHeight - scaledHeight; break;
    }
  }

  // Create a new array for resized drawings
  const newDrawings = APP.drawings.map(drawing => {
    if (!drawing) return null;

    // Create a temporary canvas for scaling
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = scaleContent ? scaledWidth : newWidth;
    tempCanvas.height = scaleContent ? scaledHeight : newHeight;
    const tempCtx = tempCanvas.getContext('2d');

    tempCtx.imageSmoothingEnabled = false;

    const oldCanvas = document.createElement('canvas');
    oldCanvas.width = oldWidth;
    oldCanvas.height = oldHeight;
    const oldCtx = oldCanvas.getContext('2d');
    oldCtx.putImageData(drawing, 0, 0);

    if (scaleContent) {
      tempCtx.drawImage(oldCanvas, 0, 0, oldWidth, oldHeight, 0, 0, scaledWidth, scaledHeight);
    } else {
      tempCtx.drawImage(oldCanvas, offsetX, offsetY);
    }

    return tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
  });

  APP.drawings = newDrawings;
  APP.width = newWidth;
  APP.height = newHeight;

  resetCanvases(newWidth, newHeight, anchor, scaleContent);

  VIEW.currUndoRef.redo = () => {
    APP.width = newWidth;
    APP.height = newHeight;
    APP.drawings = newDrawings;
    resetCanvases(newWidth, newHeight, anchor, scaleContent);
    VIEW.render();
  };

  VIEW.render();
};

/**
 * Reset all canvases after resize
 */
const resetCanvases = (nw, nh, anchor, scaleContent) => {
  canvases.forEach(canvas => {
    const oldCanvas = VIEW[canvas].dom;
    const newCanvas = canvas === 'canvasView' ? document.querySelector('#canvas-view') : document.createElement('canvas');
    newCanvas.width = nw;
    newCanvas.height = nh;
    const newCtx = newCanvas.getContext('2d', { willReadFrequently: true });

    newCtx.imageSmoothingEnabled = false;

    let offsetX = 0, offsetY = 0;
    if (!scaleContent) {
      switch (anchor) {
        case "top-left": offsetX = 0; offsetY = 0; break;
        case "top-center": offsetX = Math.floor((nw - oldCanvas.width) / 2); offsetY = 0; break;
        case "top-right": offsetX = nw - oldCanvas.width; offsetY = 0; break;
        case "middle-left": offsetX = 0; offsetY = Math.floor((nh - oldCanvas.height) / 2); break;
        case "center": offsetX = Math.floor((nw - oldCanvas.width) / 2); offsetY = Math.floor((nh - oldCanvas.height) / 2); break;
        case "middle-right": offsetX = nw - oldCanvas.width; offsetY = Math.floor((nh - oldCanvas.height) / 2); break;
        case "bottom-left": offsetX = 0; offsetY = nh - oldCanvas.height; break;
        case "bottom-center": offsetX = Math.floor((nw - oldCanvas.width) / 2); offsetY = nh - oldCanvas.height; break;
        case "bottom-right": offsetX = nw - oldCanvas.width; offsetY = nh - oldCanvas.height; break;
      }
    }

    newCtx.drawImage(oldCanvas, offsetX, offsetY);

    VIEW[canvas].dom = newCanvas;
    VIEW[canvas].ctx = newCtx;
    VIEW[canvas].imgData = newCtx.getImageData(0, 0, nw, nh);
  });

  centerCanvas();

  setTimeout(() => {
    // Update background canvas
    const canvasBackground = document.querySelector('#canvas-background');
    canvasBackground.width = nw;
    canvasBackground.height = nh;

    const ctxBackground = canvasBackground.getContext('2d', { willReadFrequently: true });
    const gridSize = 1;
    for (let y = 0; y < nh; y += gridSize) {
      for (let x = 0; x < nw; x += gridSize) {
        ctxBackground.fillStyle = (x / gridSize + y / gridSize) % 2 === 0 ? '#cccccc' : '#ffffff';
        ctxBackground.fillRect(x, y, gridSize, gridSize);
      }
    }
  }, 0);
};

const centerCanvas = () => {
  const canvasWidth = APP.width;
  const canvasHeight = APP.height;

  // Get workspace dimensions
  const { clientWidth: workspaceWidth, clientHeight: workspaceHeight } = document.querySelector('#workspace');

  // Define fixed padding
  const padding = 20; // Fixed value

  // Calculate available space
  const availableWidth = workspaceWidth - padding * 2;
  const availableHeight = workspaceHeight - padding * 2;

  // Calculate scale factor to fit canvas within the available space
  const scaleX = availableWidth / canvasWidth;
  const scaleY = availableHeight / canvasHeight;

  // Choose the smaller scaling factor to fit canvas within bounds
  const newScale = Math.min(scaleX, scaleY);

  // Recalculate available width/height based on newScale for centering
  const scaledCanvasWidth = canvasWidth * newScale;
  const scaledCanvasHeight = canvasHeight * newScale;

  // Center the canvas within the workspace
  const newTranslateX = (workspaceWidth - scaledCanvasWidth) / 2;
  const newTranslateY = (workspaceHeight - scaledCanvasHeight) / 2;

  // Apply scale and translation to the workspace
  VIEW.workspace.scale = newScale;
  console.log(newScale)
  VIEW.workspace.translate.x = newTranslateX;
  VIEW.workspace.translate.y = newTranslateY;

  // Re-render the workspace
  VIEW.render();
}

export const ENV = window.location.href.includes('localhost:4000') | window.location.href.includes('0.0.0.0:3000/') ? 'DEV' : 'PROD'
export const APP = {}
export const VIEW = { render: undefined }
export const canvases = [
  'canvasPreview',
  'canvasTemp',
  'canvasTemp2',
  'canvasFinal',
  'canvasView',
  'canvasLasso',
  'canvasSelection',
  'canvasCopy'
]

export const MAX_FRAME_COUNT = 100

const initAppDefault = (w, h) => {
  APP.version = 0.2
  APP.width = w
  APP.height = h
  APP.tool = 'pencil'

  APP.frameActive = 0
  APP.layerActive = 0
  APP.frameCount = 1
  APP.layerCount = 1
  APP.layers = [
    {
      name: 'Layer 1',
      hidden: false,
      frames: [new ImageData(w, h)],
      clips: new Array(MAX_FRAME_COUNT).fill(0) // new
    }
  ]

  APP.clipActive = 1 // new
  APP.drawings = [null] // new - consists of ImageData Objects that are sized with APP.width and APP.height

  APP.onionSkin = { // new
    framesAhead: 3,
    framesBehind: 3,
  }

  APP.grid = {
    enabled: false,
    size: 1,
  }

  APP.fps = 12
  
  APP.color = {}
  APP.color.rgb = [100, 188, 156, 255]
  APP.color.hsl = RGBtoHSL(APP.color.rgb)

  APP.palettes = [
    [...DEFAULT_COLOR_PALETTE],
    [...NES_COLOR_PALETTE]
  ]

  APP.paletteActive = 0
  APP.palette = APP.palettes[APP.paletteActive]
  
  Object.seal(APP)
}

export const initCanvases = () => {
  canvases.forEach(canvas => {
    VIEW[canvas].dom = canvas === 'canvasView' ? document.querySelector('#canvas-view') : document.createElement('canvas')
    VIEW[canvas].dom.width = APP.width
    VIEW[canvas].dom.height = APP.height
    VIEW[canvas].ctx = VIEW[canvas].dom.getContext('2d', { willReadFrequently: true })
    VIEW[canvas].imgData = VIEW[canvas].ctx.getImageData(0, 0, APP.width, APP.height)
  })

  VIEW.canvasTimeline = document.querySelector('#timeline-canvas')
  VIEW.canvasTimelineTemp = document.createElement('canvas')

  VIEW.canvasTimelineThumbnail = document.createElement('canvas')
  VIEW.canvasTimelineThumbnail.width = APP.width
  VIEW.canvasTimelineThumbnail.height = APP.height
    
  centerCanvas()

  setTimeout(() => {
    const canvasBackground = document.querySelector('#canvas-background');
    canvasBackground.width = APP.width;
    canvasBackground.height = APP.height;
    
    const ctxBackground = canvasBackground.getContext('2d', { willReadFrequently: true });
  
    // Draw transparency grid pattern
    let gridSize = 1

    if (APP.width > 100 || APP.height > 100) gridSize = 4

    for (let y = 0; y < canvasBackground.height; y += gridSize) {
      for (let x = 0; x < canvasBackground.width; x += gridSize) {
        ctxBackground.fillStyle = (x / gridSize + y / gridSize) % 2 === 0 ? '#cccccc' : '#ffffff'; // Alternating colors
        ctxBackground.fillRect(x, y, gridSize, gridSize);
      }
    }
  }, 0)
}

const initViewDefault = (preventOnMount) => {
  VIEW.activeInput = {
    id: '',
    val: ''
  }

  VIEW.window = {
    request: '',
    mouseDown: false,
    startX: 0,
    startY: 0,
    prevX: 0,
    prevY: 0,
    currX: 0,
    currY: 0
  }

  VIEW.file = { open: false }
  VIEW.newCanvas = { open: false, w: 32, h: 32 }
  VIEW.downloadCanvas = { open: false, size: 1, type: 'gif' }
  VIEW.color = { open: false }
  VIEW.gifLibraryLoaded = false
  VIEW.exporting = false

  VIEW.brushSize = 1

  VIEW.timerID = undefined
  VIEW.isPlaying = false
  VIEW.onionSkinning = false
  VIEW.frameMode = false
  VIEW.multiSelected = new Set()
  VIEW.multiSelectMode = false

  VIEW.lasso = {
    isSelecting: false,
    canSelect: true,
    active: false,
    points: []
  }
  
  VIEW.undo = []
  VIEW.undoPos = -1
  VIEW.currUndoRef = {}
  
  VIEW.canvasTimeline = undefined
  VIEW.canvasTimelineTemp = undefined
  VIEW.canvasTimelineThumbnail = undefined

  VIEW.fps = 8

  VIEW.workspace = {
    scale: 1,
    translate: { x: 0, y: 0 },
    rotate: 0,
    transformDelta: 0,
    touchAction: '',
    touchCount: 0,
    currentTouchDistance: 0,
    initialTouchDistance: 0,
    initialTouchAngle: 0,
    initialTouchMidpoint: { x: 0, y: 0 },
    previousScale: 0,
    lastBrushPosition: null,
    lastTimestamp: null,
    gesture: ''
  }

  VIEW.copy = {
    canPaste: false,
    extract: null,
    minLayer: 0,
    maxLayer: 0
  }

  VIEW.lassoCopy = {
    enabled: null,
    state: null,
    lassoCanvas: null,
    selectionCanvas: null,
  }

  VIEW.announcements = {
    open: false,
    updates: []
  }

  VIEW.debug = ''

  VIEW.resizeCanvas = {
    open: false,
    width: APP.width,
    height: APP.height,
    anchor: 'center',
    scale: false
  }

  VIEW.moveCanvas = false

  VIEW.mirror = {
    x: false,
    y: false,
  }

  VIEW.shape = {
    filled: false,
  }

  VIEW.canvasSettings = false

  // need to reset these on new project
  canvases.forEach(canvas => {
    VIEW[canvas] = {
      dom: undefined,
      ctx: undefined,
      imgData: undefined
    }
  })

  if (preventOnMount !== true) {
    initCanvases()
  }

  Object.seal(VIEW)
}

export const newData = (w, h, preventOnMount) => {
  initAppDefault(w, h)
  initViewDefault(preventOnMount)
}