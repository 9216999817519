import { h, render } from 'preact'
import { APP, VIEW } from '../state'
import { addToUndo } from '../undo-redo'
import { setRGB } from './color'
import { clamp, cloneImageData, cloneLasso, deepCloneLayers, newDrawing } from '../utils'
import { completeLasso } from './timeline'

const freezeFrameState = (type, str) => {
  // wow got desperate
  const snapLayers = deepCloneLayers(APP.layers)
  const prevLayers = deepCloneLayers(snapLayers)

  const prevClipActive = APP.clipActive
  const prevFrame = APP.frameActive;
  const prevLayer = APP.layerActive;
  const prevFrameActive = APP.frameActive
  const prevFrameCount = APP.frameCount

  const copy = new ImageData(APP.width, APP.height)
  const index = APP.layers[APP.layerActive].clips[APP.frameActive]
  if (index > 0) copy.data.set(APP.drawings[index].data)
  const layerActive = APP.layerActive
  const frameActive = APP.frameActive
  const clipActive = APP.clipActive
  const selectionImgData = cloneImageData(VIEW.canvasSelection.imgData)
  const lassoImgData = cloneImageData(VIEW.canvasLasso.imgData)
  const lasso = cloneLasso(VIEW.lasso)
  const tool = APP.tool


  VIEW.currUndoRef[type] = () => {
    APP.layers = deepCloneLayers(prevLayers)
    APP.clipActive = prevClipActive
    APP.frameActive = prevFrame;
    APP.layerActive = prevLayer;
    APP.frameActive = prevFrameActive
    APP.frameCount = prevFrameCount

    APP.tool = tool
    VIEW.lasso = lasso
    VIEW.canvasSelection.imgData.data.set(selectionImgData.data)
    VIEW.canvasLasso.imgData.data.set(lassoImgData.data)
    VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, 0, 0);
    VIEW.canvasLasso.ctx.putImageData(VIEW.canvasLasso.imgData, 0, 0);
    APP.clipActive = clipActive
    APP.frameActive = frameActive
    APP.layerActive = layerActive
    const index = APP.layers[layerActive].clips[frameActive]
    APP.drawings[index].data.set(copy.data)

    if (type === 'undo' && str === 'lasso') {
      VIEW.lasso.active = false
    }
  }
}

export const setupUndo = (str) => {
  addToUndo(str || APP.tool)
  freezeFrameState('undo', str)
}

export const setupRedo = () => {
  freezeFrameState('redo')
}

const drawToDebug = (source) => {
  const canvas = document.querySelector("#canvas-debug")
  const ctx = canvas.getContext('2d')
  ctx.putImageData(source, 0, 0)
}

const getPoint = (imgDataArr, x, y, w, h) => {
  if (!imgDataArr) throw Error(`setPoint: ${imgDataArr} undefined`)
  if (!imgDataArr.length) throw Error(`setPoint: ${imgDataArr} not a valid array`)

  if (x >= 0 && x < w && y >= 0 && y < h) { // check bounds
    const i = (x + w * y) * 4
    return [
      imgDataArr[i + 0],
      imgDataArr[i + 1],
      imgDataArr[i + 2],
      imgDataArr[i + 3]
    ]
  }

  return [0, 0, 0, 0]
}

const setPoint = (imgDataArr, x, y, w, h, color) => {
  if (!imgDataArr) throw Error(`setPoint: ${imgDataArr} undefined`)
  if (!imgDataArr.length) throw Error(`setPoint: ${imgDataArr} not a valid array`)
  
  if (x >= 0 && x < w && y >= 0 && y < h) { // check bounds
    const i = (x + w * y) * 4
    imgDataArr[i + 0] = color[0]
    imgDataArr[i + 1] = color[1]
    imgDataArr[i + 2] = color[2]
    imgDataArr[i + 3] = color[3]
  }
}

const areRGBAsEqual = (c1, a, c2, b) => {
  return (
    c1[a + 0] === c2[b + 0] &&
    c1[a + 1] === c2[b + 1] &&
    c1[a + 2] === c2[b + 2] &&
    c1[a + 3] === c2[b + 3]
  )
}

const getColorAtPixel = (data, x, y, w) => {
  const linearCord = (y * w + x) * 4

  return [
    data[linearCord + 0],
    data[linearCord + 1],
    data[linearCord + 2],
    data[linearCord + 3]
  ]
}

const fill = (canvasImgData, startX, startY, w, h, color) => { 
  var linear_cords = (startY * w + startX) * 4;
  var pixel_stack = [{ x: startX, y: startY }];
  var original_color = getColorAtPixel(canvasImgData, startX, startY, w);

  if (areRGBAsEqual(color, 0, original_color, 0)) {
    return;
  }

  // If lasso is active but the start point is outside, cancel filling
  if (VIEW.lasso.active && !isPointInLasso(startX, startY, VIEW.lasso.points)) {
    return;
  }

  while (pixel_stack.length > 0) {
    var new_pixel = pixel_stack.shift();
    var x = new_pixel.x;
    var y = new_pixel.y;

    linear_cords = (y * w + x) * 4;

    while (
      y-- >= 0 &&
      canvasImgData[linear_cords + 0] === original_color[0] &&
      canvasImgData[linear_cords + 1] === original_color[1] &&
      canvasImgData[linear_cords + 2] === original_color[2] &&
      canvasImgData[linear_cords + 3] === original_color[3]
    ) {
      linear_cords -= w * 4;
    }

    linear_cords += w * 4;
    y++;

    var reached_left = false;
    var reached_right = false;

    while (
      y++ < h &&
      canvasImgData[linear_cords + 0] === original_color[0] &&
      canvasImgData[linear_cords + 1] === original_color[1] &&
      canvasImgData[linear_cords + 2] === original_color[2] &&
      canvasImgData[linear_cords + 3] === original_color[3]
    ) {
      // Ensure we only fill within the lasso if it's active
      if (!VIEW.lasso.active || isPointInLasso(x, y, VIEW.lasso.points)) {
        canvasImgData[linear_cords + 0] = color[0];
        canvasImgData[linear_cords + 1] = color[1];
        canvasImgData[linear_cords + 2] = color[2];
        canvasImgData[linear_cords + 3] = color[3];

        if (x > 0) {
          if (
            canvasImgData[linear_cords - 4 + 0] === original_color[0] &&
            canvasImgData[linear_cords - 4 + 1] === original_color[1] &&
            canvasImgData[linear_cords - 4 + 2] === original_color[2] &&
            canvasImgData[linear_cords - 4 + 3] === original_color[3]
          ) {
            if (!reached_left && (!VIEW.lasso.active || isPointInLasso(x - 1, y, VIEW.lasso.points))) {
              pixel_stack.push({ x: x - 1, y: y });
              reached_left = true;
            }
          } else if (reached_left) {
            reached_left = false;
          }
        }

        if (x < w - 1) {
          if (
            canvasImgData[linear_cords + 4 + 0] === original_color[0] &&
            canvasImgData[linear_cords + 4 + 1] === original_color[1] &&
            canvasImgData[linear_cords + 4 + 2] === original_color[2] &&
            canvasImgData[linear_cords + 4 + 3] === original_color[3]
          ) {
            if (!reached_right && (!VIEW.lasso.active || isPointInLasso(x + 1, y, VIEW.lasso.points))) {
              pixel_stack.push({ x: x + 1, y: y });
              reached_right = true;
            }
          } else if (reached_right) {
            reached_right = false;
          }
        }
      }

      linear_cords += w * 4;
    }
  }
};

const line = (startX, startY, endX, endY, func) => {
  let dx = Math.abs(endX - startX)
  let dy = Math.abs(endY - startY)

  let xDir = endX - startX >= 0 ? 1 : -1
  let yDir = endY - startY >= 0 ? 1 : -1
  
  let lineX = startX
  let lineY = startY

  let step = dx >= dy ? dx : dy

  dx = dx / step
  dy = dy / step
  
  let i = 0
  while (i < step) {
    func(Math.floor(lineX), Math.floor(lineY))

    lineX += (dx * xDir)
    lineY += (dy * yDir)
    i += 1
  }

  func(Math.floor(lineX), Math.floor(lineY))
}

const circle = (xCenter, yCenter, currX, currY, func) => {
  let radius = Math.floor(Math.sqrt(Math.pow((currX - xCenter), 2) + Math.pow((currY - yCenter), 2)))

  if (radius <= 0) return

  let x = 0
  let y = radius
  let p = 1 - radius

  const circlePlot = () => {
    func(xCenter + x, yCenter + y)
    func(xCenter + y, yCenter + x)
    func(xCenter - x, yCenter + y)
    func(xCenter - y, yCenter + x)
    func(xCenter + x, yCenter - y)
    func(xCenter + y, yCenter - x)
    func(xCenter - x, yCenter - y)
    func(xCenter - y, yCenter - x)
  }

  // Plot first set of points
  circlePlot(xCenter, yCenter, x, y)

  while (x <= y) {
    x++
    if (p < 0) {
      p += 2 * x + 1 // Mid point is inside therefore y remains same
    } else { // Mid point is outside the circle so y decreases
      y--
      p += 2 * (x - y) + 1
    }

    circlePlot(xCenter, yCenter, x, y)
  }
}

const circleFilled = (xCenter, yCenter, currX, currY, func) => {
  let radius = Math.floor(Math.sqrt(Math.pow((currX - xCenter), 2) + Math.pow((currY - yCenter), 2)));

  if (radius <= 0) return;

  let x = 0;
  let y = radius;
  let p = 1 - radius;

  const fillCircleScanline = (xc, yc, x, y) => {
    // Instead of just plotting points, we draw horizontal lines (scanlines)
    for (let i = -x; i <= x; i++) {
      func(xc + i, yc + y);
      func(xc + i, yc - y);
    }
    for (let i = -y; i <= y; i++) {
      func(xc + i, yc + x);
      func(xc + i, yc - x);
    }
  };

  // Fill initial central scanline
  fillCircleScanline(xCenter, yCenter, x, y);

  while (x <= y) {
    x++;
    if (p < 0) {
      p += 2 * x + 1;
    } else {
      y--;
      p += 2 * (x - y) + 1;
    }

    fillCircleScanline(xCenter, yCenter, x, y);
  }
};



const squareFilled = (startX, startY, endX, endY, func) => {
  let points = []

  let dx = Math.abs(endX - startX)
  let dy = Math.abs(endY - startY)

  let xDir = endX - startX >= 0 ? 1 : -1
  let yDir = endY - startY >= 0 ? 1 : -1

  let lineX = startX
  let lineY = startY

  let xStep = 0
  let yStep = 0

  while (xStep <= dx) {
    yStep = 0
    lineY = startY

    while (yStep <= dy) {
      func(lineX, lineY)
      //points.push({ x: lineX, y: lineY })

      lineY += (1 * yDir)
      yStep += 1
    }

    lineX += (1 * xDir)
    xStep += 1
  }

  return points
}

const square = (startX, startY, endX, endY, func) => {
  let dx = Math.abs(endX - startX)
  let dy = Math.abs(endY - startY)

  let xDir = endX - startX >= 0 ? 1 : -1
  let yDir = endY - startY >= 0 ? 1 : -1

  let lineX = startX
  let lineY = startY
  let i = 0

  func(lineX, lineY)

  while (i < dx) {
    lineX += (1 * xDir)
    func(lineX, startY)
    func(lineX, (startY + (dy * yDir)))
    i += 1
  }

  i = 0

  while (i < dy) {
    lineY += (1 * yDir)
    func(startX, lineY)
    func((startX + (dx * xDir)), lineY)
    i += 1
  }
}

// const isPointInLasso = (x, y, lassoPoints) => {
//   let inside = false;
//   let j = lassoPoints.length - 1; // Last point connects to the first

//   for (let i = 0; i < lassoPoints.length; i++) {
//     let xi = lassoPoints[i].x, yi = lassoPoints[i].y;
//     let xj = lassoPoints[j].x, yj = lassoPoints[j].y;

//     // Ensure strict exclusivity by avoiding boundary intersections
//     if (yi === y && xi === x) return false; // Exclude exact points
//     if (yj === y && xj === x) return false; // Exclude exact points

//     let intersect = ((yi > y) !== (yj > y)) &&
//                     (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    
//     if (intersect) inside = !inside;
//     j = i;
//   }

//   return inside;
// }

const isPointInLasso = (x, y, lassoPoints) => {
  let inside = false;
  let j = lassoPoints.length - 1; // Last point connects to the first

  for (let i = 0; i < lassoPoints.length; i++) {
    let xi = lassoPoints[i].x, yi = lassoPoints[i].y;
    let xj = lassoPoints[j].x, yj = lassoPoints[j].y;

    // Check if the point is exactly on a vertex (inclusive)
    if (xi === x && yi === y) return true;
    if (xj === x && yj === y) return true;

    // Check if the point is on the edge (inclusive)
    if ((y - yi) * (xj - xi) === (x - xi) * (yj - yi) &&
        Math.min(xi, xj) <= x && x <= Math.max(xi, xj) &&
        Math.min(yi, yj) <= y && y <= Math.max(yi, yj)) {
      return true;
    }

    // Standard ray-casting algorithm to determine if inside
    let intersect = ((yi > y) !== (yj > y)) &&
                    (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    
    if (intersect) inside = !inside;
    j = i;
  }

  return inside;
};


const mergeImageData = (source, target) => {
  // Error checks for ImageData validity
  if (!source || !target) {
    console.error("Error: Source or target ImageData is undefined.");
    return;
  }

  if (
    !source.data || !target.data ||
    !(source.data instanceof Uint8ClampedArray) ||
    !(target.data instanceof Uint8ClampedArray)
  ) {
    console.error("Error: Invalid ImageData format in source or target.");
    return;
  }

  const width = Math.min(target.width, source.width);
  const height = Math.min(target.height, source.height);

  const targetData = target.data;
  const sourceData = source.data;

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * target.width + x) * 4; // Calculate the pixel index

      if (sourceData[index + 3] === 255) { // Check if the source pixel is opaque (alpha === 255)
        // Copy RGBA values from source to target
        targetData[index] = sourceData[index];       // Red
        targetData[index + 1] = sourceData[index + 1]; // Green
        targetData[index + 2] = sourceData[index + 2]; // Blue
        targetData[index + 3] = sourceData[index + 3]; // Alpha
      }
    }
  }
}

export const copyDrawing = () => {
  const index = APP.layers[APP.layerActive].clips[APP.frameActive]
  const source = VIEW.lasso.points.length ? VIEW.canvasSelection.imgData : APP.drawings[index]
  const target = VIEW.canvasCopy.imgData
  target.data.fill(0); // empty image data frame
  mergeImageData(source, target)

  if (VIEW.lasso.points.length) {
    const lassoCanvasClone = new ImageData(APP.width, APP.height)
    lassoCanvasClone.data.set(VIEW.canvasLasso.imgData.data)
    const selectionCanvasClone = new ImageData(APP.width, APP.height)
    selectionCanvasClone.data.set(VIEW.canvasSelection.imgData.data)
    VIEW.lassoCopy.enabled = true
    VIEW.lassoCopy.state = cloneLasso(VIEW.lasso)
    VIEW.lassoCopy.lassoCanvas = lassoCanvasClone
    VIEW.lassoCopy.selectionCanvas = selectionCanvasClone
    completeLasso()
  } else {
    VIEW.lassoCopy.enabled = false
  }

  VIEW.render()
}

export const pasteDrawing = () => {
  let index
  if (APP.clipActive === 0) {
    index = newDrawing()
  } else {
    index = APP.layers[APP.layerActive].clips[APP.frameActive]
  }

  setupUndo('Paste')

  if (VIEW.lassoCopy.enabled) {
    commitSelectionBuffer()

    VIEW.lasso = cloneLasso(VIEW.lassoCopy.state)
    VIEW.canvasLasso.imgData.data.set(VIEW.lassoCopy.lassoCanvas.data)
    VIEW.canvasSelection.imgData.data.set(VIEW.lassoCopy.selectionCanvas.data)
    VIEW.canvasLasso.ctx.putImageData(VIEW.canvasLasso.imgData, 0, 0)
    VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, 0, 0)
    APP.tool = 'move'
  } else {
    const source = VIEW.canvasCopy.imgData
    const target = APP.drawings[index]
    mergeImageData(source, target)
  }

  setupRedo()

  VIEW.render()
}

const extractLassoContent = (source, lassoPoints, target) => {
  for (let y = 0; y < APP.height; y++) {
    for (let x = 0; x < APP.width; x++) {
      const index = (y * APP.width + x) * 4;
  
      if (isPointInLasso(x, y, lassoPoints)) {
        // Copy pixel from source to target
        target[index] = source[index];     
        target[index + 1] = source[index + 1]; 
        target[index + 2] = source[index + 2]; 
        target[index + 3] = source[index + 3]; 
  
        // Set the pixel in the source to transparent/black
        source[index] = 0;
        source[index + 1] = 0;
        source[index + 2] = 0;
        source[index + 3] = 0;
      }
    }
  }
}

export const commitSelectionBuffer = () => {
  let index = APP.layers[APP.layerActive].clips[APP.frameActive]
  if (index === 0) {
    // console.error('trying to commit an empty frame')
    return
  }
  
  const target = APP.drawings[index].data 
  const selectionData = VIEW.canvasSelection.imgData.data;

  // Copy only non-transparent pixels from the selection to `target`
  for (let i = 0; i < selectionData.length; i += 4) {
    if (selectionData[i + 3] > 0) { // Check alpha channel (not fully transparent)
      target[i] = selectionData[i];     // Red
      target[i + 1] = selectionData[i + 1]; // Green
      target[i + 2] = selectionData[i + 2]; // Blue
      target[i + 3] = selectionData[i + 3]; // Alpha
    }
  }

  VIEW.canvasSelection.ctx.clearRect(0, 0, APP.width, APP.height);
  VIEW.canvasSelection.imgData = new ImageData(APP.width, APP.height);
}

export const resetLasso = () => {
  VIEW.canvasSelection.ctx.clearRect(0, 0, APP.width, APP.height);
  VIEW.canvasSelection.imgData = new ImageData(APP.width, APP.height);

  VIEW.canvasLasso.ctx.clearRect(0, 0, APP.width, APP.height);
  VIEW.canvasLasso.imgData = new ImageData(APP.width, APP.height);

  VIEW.lasso.isSelecting = true
  VIEW.lasso.points = []
  VIEW.canvasLasso.imgData.data.fill(0)
}

export const deleteSelected = () => {
  setupUndo('Delete', 'Drawing')
  const target = VIEW.canvasSelection.imgData.data

  for (let y = 0; y < APP.height; y++) {
    for (let x = 0; x < APP.width; x++) {
      const index = (y * APP.width + x) * 4;
  
      if (isPointInLasso(x, y, VIEW.lasso.points)) {
        // Copy pixel from source to target
        target[index] = 0  
        target[index + 1] = 0; 
        target[index + 2] = 0; 
        target[index + 3] = 0; 
      }
    }
  }

  VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, 0 ,0)
  commitSelectionBuffer()
  completeLasso()

  setupRedo()

  VIEW.render()
}

export const paintCanvas = (gestureEvent) => {
  // Reset
  VIEW.canvasPreview.ctx.clearRect(0, 0, APP.width, APP.height)
  VIEW.canvasPreview.imgData = VIEW.canvasPreview.ctx.getImageData(0, 0, APP.width, APP.height)

  // Whole or Selection
  let index = APP.layers[APP.layerActive].clips[APP.frameActive]

  if (VIEW.lasso.active && index === 0) return
  if (APP.tool === 'eraser' && index === 0) return
  if (APP.tool === 'move' && index === 0) return
  if (APP.tool === 'eye-dropper' && index === 0) return

  // Automatically create a new drawing if you start on canvas and its blank
  if (index === 0 && gestureEvent === 'start') {
    index = newDrawing()
  }
  
  const preview = VIEW.canvasPreview.imgData.data
  const target = APP.clipActive !== 0 && index !== 0 ? APP.drawings[index].data : preview // or selection buffer

  // Translate coordinates based on current screen position and canvas scale
  const bb = VIEW.canvasView.dom.getBoundingClientRect()

  const scaleX = bb.width / VIEW.canvasView.dom.width
  const scaleY = bb.height / VIEW.canvasView.dom.height

  const startX = Math.floor((VIEW.window.startX - bb.x) / scaleX)
  const startY = Math.floor((VIEW.window.startY - bb.y) / scaleY)
  const prevX = Math.floor((VIEW.window.prevX - bb.x) / scaleX)
  const prevY = Math.floor((VIEW.window.prevY - bb.y) / scaleY)
  const currX = Math.floor((VIEW.window.currX - bb.x) / scaleX)
  const currY = Math.floor((VIEW.window.currY - bb.y) / scaleY)

  const setBrushPoints = (canvas, x, y, w, h, color) => {
    const brushSize = VIEW.brushSize - 1
    const halfBrush = Math.floor(brushSize / 2); // Properly handle odd brush sizes
  
    squareFilled(
      x - halfBrush, 
      y - halfBrush, 
      x + (brushSize - halfBrush), 
      y + (brushSize - halfBrush), 
      (px, py) => {
        setPoint(canvas, px, py, w, h, color);
      }
    );
  };
  
  if (gestureEvent === 'clear-hover' && APP.tool !== 'eye-dropper' && APP.tool !== 'move') {
    setBrushPoints(preview, -100, -100, APP.width, APP.height, APP.tool !== 'eraser' ? APP.color.rgb : [0, 0, 0, 50])
    VIEW.render()
    return
  }

  if (gestureEvent === 'hover' && APP.tool !== 'eye-dropper' && APP.tool !== 'move') {
    if (!VIEW.lasso.active) {
      setBrushPoints(preview, currX, currY, APP.width, APP.height, APP.tool !== 'eraser' ? APP.color.rgb : [0, 0, 0, 50])
    } else {
      let color = [0, 0, 0, 50]
      if (isPointInLasso(currX, currY, VIEW.lasso.points)) color = APP.color.rgb
      setBrushPoints(preview, currX, currY, APP.width, APP.height, color)
    }

    VIEW.canvasPreview.ctx.putImageData(VIEW.canvasPreview.imgData, 0, 0)
    VIEW.render()

    return
  }
  
  if (index > 0 && VIEW.lasso.active) {
    const lassoTarget = VIEW.canvasLasso.imgData

    const inLasso = isPointInLasso(currX, currY, VIEW.lasso.points)

    if (!inLasso && gestureEvent === 'start') {
      setupUndo('lasso')
      commitSelectionBuffer()
      resetLasso()

      VIEW.render()
      return
    }

    if (VIEW.lasso.isSelecting && gestureEvent === 'resume') {
      line(prevX, prevY, currX, currY, (x, y) => {
        const lastPoint = VIEW.lasso.points[VIEW.lasso.points.length - 1];

        const clampedX = clamp(x, 0, APP.width - 1)
        const clampedY = clamp(y, 0, APP.height - 1)
        
        // Only add if it's not a duplicate of the last point
        if (!lastPoint || lastPoint.x !== x || lastPoint.y !== y) {
          VIEW.lasso.points.push({ x: clampedX, y: clampedY })
        }
        
        setBrushPoints(lassoTarget.data, clampedX, clampedY, APP.width, APP.height, [0, 0, 0, 150])
      })
      VIEW.canvasLasso.ctx.putImageData(lassoTarget, 0, 0)
      
      VIEW.render()
      return
    }

    if (VIEW.lasso.isSelecting && gestureEvent === 'end') {
      if (VIEW.lasso.points.length > 1) {
        line(currX, currY, startX, startY, (x, y) => {
          VIEW.lasso.points.push({ x, y })
          setBrushPoints(lassoTarget.data, x, y, APP.width, APP.height, [0, 0, 0, 150])
        })
        VIEW.canvasLasso.ctx.putImageData(lassoTarget, 0, 0)
        VIEW.lasso.isSelecting = false
        APP.tool = 'move'
  
        extractLassoContent(target, VIEW.lasso.points, VIEW.canvasSelection.imgData.data)
        VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, 0, 0);
      } else {
        commitSelectionBuffer()
        resetLasso()
        // VIEW.lasso.active = !VIEW.lasso.active
      }

      setupRedo()

      VIEW.render()
      return
    }
  }

  // Setup Undo
  if (gestureEvent === 'start' && APP.tool !== 'eye-dropper') {
    setupUndo()
  }

  
  // Eye dropper
  if (gestureEvent === 'end' && APP.tool === 'eye-dropper') {
    const color = getPoint(target, currX, currY, APP.width, APP.height)
    
    if (color[3] !== 0) {
      setRGB(color)
    }
  }

  // Fill
  if (gestureEvent === 'end' && APP.tool === 'fill') {
    if (VIEW.lasso.active && isPointInLasso(currX, currY, VIEW.lasso.points)) {
      fill(VIEW.canvasSelection.imgData.data, currX, currY, APP.width, APP.height, APP.color.rgb)
      VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, 0, 0);
    }
    
    if (!VIEW.lassoActive) {
      fill(target, currX, currY, APP.width, APP.height, APP.color.rgb)
    }
  }

  // Points
  if (APP.tool === 'pencil' || APP.tool === 'eraser') {
    if (APP.tool === 'eraser') {
      setBrushPoints(preview, currX, currY, APP.width, APP.height, [0, 0, 0, 50])
    }
    
    line(prevX, prevY, currX, currY, (x, y) => {
      if (VIEW.lasso.active && isPointInLasso(x, y, VIEW.lasso.points)) {
        setBrushPoints(VIEW.canvasSelection.imgData.data, x, y, APP.width, APP.height, APP.tool === 'pencil' ? APP.color.rgb : [0, 0, 0, 0])
        VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, 0, 0);
      }
      
      if (!VIEW.lasso.active) {
        setBrushPoints(target, x, y, APP.width, APP.height, APP.tool === 'pencil' ? APP.color.rgb : [0, 0, 0, 0])
      }
    })

    // Mirroring logic
    if (VIEW.mirror.x) {
      let mirrorX = APP.width - currX;
      line(APP.width - prevX, prevY, mirrorX, currY, (x, y) => {
        setBrushPoints(target, x, y, APP.width, APP.height, APP.color.rgb);
      });
    }
  
    if (VIEW.mirror.y) {
      let mirrorY = APP.height - currY;
      line(prevX, APP.height - prevY, currX, mirrorY, (x, y) => {
        setBrushPoints(target, x, y, APP.width, APP.height, APP.color.rgb);
      });
    }
  
    // If both are true, mirror in both axes
    if (VIEW.mirror.x && VIEW.mirror.y) {
      let mirrorX = APP.width - currX;
      let mirrorY = APP.height - currY;
      line(APP.width - prevX, APP.height - prevY, mirrorX, mirrorY, (x, y) => {
        setBrushPoints(target, x, y, APP.width, APP.height, APP.color.rgb);
      });
    }
  }

  // if (APP.tool === 'mirror') {
  //   // Draw the main stroke
  //   line(prevX, prevY, currX, currY, (x, y) => {
  //     if (VIEW.lasso.active && isPointInLasso(x, y, VIEW.lasso.points)) {
  //       setBrushPoints(VIEW.canvasSelection.imgData.data, x, y, APP.width, APP.height, APP.color.rgb);
  //       VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, 0, 0);
  //     } else {
  //       setBrushPoints(target, x, y, APP.width, APP.height, APP.color.rgb);
  //     }
  //   });
  

  // }
  

  // Geometry
  if (APP.tool === 'line' || APP.tool === 'circle' || APP.tool === 'square') {
    let funcs

    if (VIEW.shape.filled) {
      funcs = { 'line': line, 'circle': circleFilled, 'square': squareFilled }
    } else {
      funcs = { 'line': line, 'circle': circle, 'square': square }
    }
    
    
    funcs[APP.tool](startX, startY, currX, currY, (x, y) => {
      if (VIEW.lasso.active && isPointInLasso(x, y, VIEW.lasso.points)) {
        const selectionTarget = VIEW.canvasSelection.imgData.data
        setBrushPoints(gestureEvent === 'start' || gestureEvent === 'resume' ? preview : selectionTarget, x, y, APP.width, APP.height, APP.color.rgb)
        VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, 0, 0);
      }

      if (!VIEW.lasso.active) {
        setBrushPoints(gestureEvent === 'start' || gestureEvent === 'resume' ? preview : target, x, y, APP.width, APP.height, APP.color.rgb)
      }
    })
  }

  // Move
  if (APP.tool === 'move') {
    const index = APP.layers[APP.layerActive].clips[APP.frameActive]
    let offsetX = prevX - startX;
    let offsetY = prevY - startY;

    if (VIEW.lasso.points.length) {
      if (gestureEvent === 'resume') {    
        // Clear the original selection and lasso canvases
        VIEW.canvasSelection.ctx.clearRect(0, 0, APP.width, APP.height);
        VIEW.canvasLasso.ctx.clearRect(0, 0, APP.width, APP.height);
    
        // Move Selection
        VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, offsetX, offsetY);
    
        // Move Lasso
        // VIEW.canvasLasso.ctx.putImageData(VIEW.canvasLasso.imgData, offsetX, offsetY);
        // VIEW.canvasLasso.ctx.clearRect(0, 0, APP.width, APP.height);
    
        // Apply movement delta to lasso points
        let deltaX = currX - prevX;
        let deltaY = currY - prevY;
    
        VIEW.lasso.points = VIEW.lasso.points.map(point => ({
          x: point.x + deltaX,
          y: point.y + deltaY
        }));
      }
    
      if (gestureEvent === 'end') {
        VIEW.canvasLasso.ctx.putImageData(VIEW.canvasLasso.imgData, offsetX, offsetY);
        // Save the new image position to prevent reset on next move
        VIEW.canvasSelection.imgData = VIEW.canvasSelection.ctx.getImageData(0, 0, APP.width, APP.height);
        VIEW.canvasLasso.imgData = VIEW.canvasLasso.ctx.getImageData(0, 0, APP.width, APP.height);
      }
    }
    else {
      if (gestureEvent === 'start') {
        // Frame to Selection
        VIEW.canvasSelection.ctx.putImageData(APP.drawings[index], 0, 0)
        VIEW.canvasSelection.imgData = VIEW.canvasSelection.ctx.getImageData(0, 0, APP.width, APP.height)
  
        // Clear main canvas
        APP.drawings[index] = new ImageData(APP.width, APP.height)
      }
  
      if (gestureEvent === 'resume') {
        // Selection to Preview
        VIEW.canvasSelection.ctx.clearRect(0, 0, APP.width, APP.height);
        VIEW.canvasSelection.ctx.putImageData(VIEW.canvasSelection.imgData, offsetX, offsetY);
      }
  
      if (gestureEvent === 'end') {
        // Selection to Preview
        VIEW.canvasSelection.imgData = VIEW.canvasSelection.ctx.getImageData(0, 0, APP.width, APP.height);
        commitSelectionBuffer()
      }
    }
  }

  // Setup Redo
  if (gestureEvent === 'end' && APP.tool !== 'eye-dropper') {
    setupRedo()
  }


  VIEW.render()

}

// Helper function to enforce bounds
export const enforceBounds = (translate, workspaceWidth, workspaceHeight, scale, canvasBounds) => {
  const scaledCanvasWidth = canvasBounds.width;
  const scaledCanvasHeight = canvasBounds.height;

  const margin = 20
  
  // Calculate min and max bounds for translation
  const minTranslateX = 0 - scaledCanvasWidth + margin;
  const maxTranslateX = workspaceWidth - margin; // Allow margin on the right
  const minTranslateY = 0 - scaledCanvasHeight + margin;
  const maxTranslateY = workspaceHeight - margin; // Allow margin at the bottom

  return {
    x: clamp(translate.x, minTranslateX, maxTranslateX),
    y: clamp(translate.y, minTranslateY, maxTranslateY),
  };
};

const handleWheel = (e) => {
  e.preventDefault();

  const workspaceRef = document.querySelector('#workspace');
  const canvasViewRef = document.querySelector('#canvas-view');
  const { left, top, width, height } = workspaceRef.getBoundingClientRect();
  const canvasViewBounds = canvasViewRef.getBoundingClientRect();
  const offsetX = e.clientX - left;
  const offsetY = e.clientY - top;
  const { scale, translate } = VIEW.workspace;
  
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  // Calculate dynamic min and max scale
  const baseScale = Math.min(viewportWidth / width, viewportHeight / height);
  const maxScale = baseScale * 50; // Allow zoom up to 5x the base scale
  const minScale = baseScale * 0.3; // Allow zoom down to 10% of the base scale

  // Check if cursor is over canvas-view
  const isCursorOverCanvasView = () => {
    return (
      e.clientX >= canvasViewBounds.left &&
      e.clientX <= canvasViewBounds.right &&
      e.clientY >= canvasViewBounds.top &&
      e.clientY <= canvasViewBounds.bottom
    );
  };

  // Check if scaling should occur (Ctrl or Cmd key)
  const isScalingKeyPressed = e.ctrlKey || e.metaKey;

  if (isScalingKeyPressed) { // Scaling
    if (!isCursorOverCanvasView()) return; // Only allow zoom if cursor is over canvas-view

    const deltaScale = Math.exp(-e.deltaY / 100);
    const newScale = Math.max(minScale, Math.min(maxScale, scale * deltaScale));

    console.log(newScale)

    const newTranslate = {
      x: offsetX - ((offsetX - translate.x) * newScale) / scale,
      y: offsetY - ((offsetY - translate.y) * newScale) / scale,
    }

    VIEW.workspace.scale = newScale
    VIEW.workspace.translate = newTranslate
    VIEW.render()
  }
  else { // Translate logic
    const newTranslate = {
      x: translate.x - e.deltaX,
      y: translate.y - e.deltaY,
    };

    // Update translate while enforcing bounds
    VIEW.workspace.translate = enforceBounds(newTranslate, width, height, scale, canvasViewBounds);
    VIEW.render();
  }
};

export const Canvas = () => {
  const gridSize = VIEW.workspace.scale * APP.grid.size;
  // const lineThickness = Math.max(1, gridSize * 0.04); // Keeps the lines proportionate
  const lineThickness = 1

  const styles = {
      width: `${APP.width * VIEW.workspace.scale}px`,
      height: `${APP.height * VIEW.workspace.scale}px`,
      backgroundImage: `
          linear-gradient(to right, black ${lineThickness}px, transparent ${lineThickness}px),
          linear-gradient(to bottom, black ${lineThickness}px, transparent ${lineThickness}px)
      `,
      backgroundSize: `${gridSize}px ${gridSize}px`,
      backgroundRepeat: 'repeat',
  };


  return (
    <div
      id="workspace"
      style={{ position: "relative" }}
      onWheel={handleWheel}
      class={`overflow-none fl-1 ${VIEW.moveCanvas ? 'cursor-grab' : `cursor-${APP.tool}`}`}
      onMouseLeave={() => { paintCanvas('clear-hover') }}
      data-request="paintCanvas"
      data-hover="paintCanvas"
    > 
      <div style={{ color: "white", position: "absolute", left: 0, top: 0 }}>
        <p>{VIEW.debug}</p>
      </div>
      <div
        id="canvas-container"
        style={{
          position: 'relative',
          transform: `translate(${VIEW.workspace.translate.x}px, ${VIEW.workspace.translate.y}px) rotate(${VIEW.workspace.rotate}deg)`,
          width: `${APP.width * VIEW.workspace.scale}px`,
          height: `${APP.height * VIEW.workspace.scale}px`,
        }}
        class="origin-top-left touch-none flex justify-center items-center"
        data-request="paintCanvas"
        data-hover="paintCanvas"
      >
        
        <canvas
          id="canvas-background"
          style={{ position: 'absolute', top: '0', left: '0', zIndex: '-1', pointerEvents: 'none', width: '100%' }}
          width={APP.width}
          height={APP.height}
        />
        <canvas
          id="canvas-view"
          width={APP.width}
          height={APP.height}
          style={{
            width: `${APP.width * VIEW.workspace.scale}px`,
            height: `${APP.height * VIEW.workspace.scale}px`,
            pointerEvents: "none",
          }}
        />

{/* <svg 
  style={{
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    pointerEvents: 'none',
  }}
  width="100%" 
  height="100%" 
  xmlns="http://www.w3.org/2000/svg"
>
<pattern id="smallGrid" width={gridSize} height={gridSize} patternUnits="userSpaceOnUse">
  <path 
d={`M ${Math.round(gridSize)} 0 L 0 0 0 ${Math.round(gridSize)}`}
fill="none" 
    stroke="#000" 
    strokeWidth="1" 
    vectorEffect="non-scaling-stroke"
    shapeRendering="crispEdges"
  />
</pattern>

<pattern id="grid" width={gridSize * 4} height={gridSize * 4} patternUnits="userSpaceOnUse">
  <rect width={gridSize * 4} height={gridSize * 4} fill="url(#smallGrid)"/>
  <path 
d={`M ${Math.round(gridSize)} 0 L 0 0 0 ${Math.round(gridSize)}`}
fill="none" 
    stroke="#000" 
    strokeWidth="1" 
    vectorEffect="non-scaling-stroke"
    shapeRendering="crispEdges"
  />
</pattern>


  <rect width="100%" height="100%" fill="url(#grid)" />
</svg> */}

        {APP.grid.enabled && <div
          id="grid-overlay"
          class="hide-at-850"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10,
            pointerEvents: 'none',
            ...styles
          }}
        />}
        {/* <canvas
          id="canvas-debug"
          width={APP.width}
          height={APP.height}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: `${APP.width * (VIEW.workspace.scale / 4)}px`,
            height: `${APP.height * (VIEW.workspace.scale / 4)}px`,
            border: '1px solid black',
            pointerEvents: "none",
          }}
        /> */}
        

      </div>
      {!VIEW.isPlaying && <div>
          {!APP.layers[APP.layerActive].hidden && VIEW.lasso.active ? (
            APP.clipActive === 0 && (
              <div
                class="fl fl-center w-full h-full"
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  pointerEvents: 'none',
                  background: 'rgb(0,0,0,.5)',
                  zIndex: '100'
                }}
              >
                <p
                  class="bg-mid p-h-15 p-v-10"
                  style={{ color: 'white', width: 'max-content', borderRadius: '4px' }}
                >
                  No Lasso on an empty frame. Disable to start drawing.
                </p>
              </div>
            )
          ) : (
            <div>
              {APP.layers[APP.layerActive].hidden && (
                <div
                  class="fl fl-center w-full h-full"
                  style={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    pointerEvents: 'none',
                    background: 'rgb(0,0,0,.5)',
                    zIndex: '100'
                  }}
                >
                  <p
                    class="bg-mid p-h-15 p-v-10"
                    style={{ color: 'white', width: 'max-content', borderRadius: '4px' }}
                  >
                    Layer is hidden.
                  </p>
                </div>
              )}
              {APP.clipActive === 0 && APP.tool === 'move' && (
                <div
                  class="fl fl-center w-full h-full"
                  style={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    pointerEvents: 'none',
                    background: 'rgb(0,0,0,.5)',
                    zIndex: '100'
                  }}
                >
                  <p
                    class="bg-mid p-h-15 p-v-10"
                    style={{ color: 'white', width: 'max-content', borderRadius: '4px' }}
                  >
                    No Move tool on an empty frame.
                  </p>
                </div>
              )}
              {APP.clipActive === 0 && APP.tool === 'eye-dropper' && (
                <div
                  class="fl fl-center w-full h-full"
                  style={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    pointerEvents: 'none',
                    background: 'rgb(0,0,0,.5)',
                    zIndex: '100'
                  }}
                >
                  <p
                    class="bg-mid p-h-15 p-v-10"
                    style={{ color: 'white', width: 'max-content', borderRadius: '4px' }}
                  >
                    No Eye dropper tool on an empty frame.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>}
    </div>
  );
};
