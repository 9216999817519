function serializeState(state) {
   return JSON.stringify(state, (key, value) => {
       if (value instanceof ImageData) {
           return {
               _type: "ImageData", // Marker to identify ImageData objects during deserialization
               width: value.width,
               height: value.height,
               data: Array.from(value.data) // Convert Uint8ClampedArray to a normal array
           };
       }
       return value;
   });
}

export const state0point2 = JSON.stringify()