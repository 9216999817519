import { h } from 'preact'
import { VIEW, APP, resizeCanvas } from '../state'
import { undo, redo } from '../undo-redo'
import { copyDrawing, pasteDrawing } from './canvas'
import { clamp } from '../utils'

const BrushSize = () => {
  return (
    <div class='fl'>
      {/* <small class="fl fl-items-center w-full" style="padding: 0px 10px;">Brush Size</small> */}
      <div title="Brush Size" class="fl" style="overflow: hidden;">
        <div class="fl fl-center" style="padding: 6px; width: 30px;">
          <img class="ptr-none" style="width: 17px;" src="img/brush-size.svg" />
        </div>
        <input
          style="width: 35px; padding: 0px; text-align: center;"
          value={VIEW.brushSize}
          onInput={(e) => {
            const val = parseInt(e.target.value)
            VIEW.brushSize = clamp(val, 1, 64)
            console.log(VIEW.brushSize)
            VIEW.render()
          }}
          onWheel={() => {}}
          type="number"
        />

        {/* <div class="select">
          <select
            onInput={(e) => {
              VIEW.brushSize = parseInt(e.target.value)
            }}
            style="padding: 0px; padding-right: 16px; padding-left: 5px;"
            value={VIEW.brushSize}
            class="h-full">
              {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((size, i) => {
                  return <option value={i}>{size}</option>
                })
              }
          </select>
        </div> */}
      </div>
    </div>
  )
}

const CopyPaste = () => {
  return (
    <div class='fl'>
      <button
        title="Copy"
        onClick={() => { copyDrawing() }}
        class="fl fl-center m-0 p-0 w-40 bord-dark-l bord-dark-r">
        <img src="img/copy.svg" />
      </button>
      <button
        title="Paste"
        onClick={() => { pasteDrawing() }}
        class="fl fl-center m-0 p-0 w-40">
        <img src="img/paste.svg" />
      </button>
    </div>
  )
}

const UndoRedo = () => {
  return (
    <div class='fl'>
      <button
        title="Undo"
        onClick={() => { undo() }}
        class="fl fl-center m-0 p-0 w-40 bord-dark-l bord-dark-r">
        <img src="img/undo.svg" />
      </button>
      <button
        title="Redo"
        onClick={() => { redo() }}
        class="fl fl-center m-0 p-0 w-40">
        <img src="img/redo.svg" />
      </button>
    </div>
  )
}

const handleGrid = () => {
  APP.grid.enabled = !APP.grid.enabled
  VIEW.render()
}

const Grid = () => {
  return (
    <div title="Grid" class="fl h-full " style="overflow: hidden; gap: 6px;">
      <button
        onClick={() => { handleGrid() }}
        style="padding: 8px; width: 30px;"
        class={`${APP.grid.enabled ? 'bg-blue' : 'bg-dark'} button fl fl-center bord-dark b-r-2`}
      >
        <img class="ptr-none" src="img/grid.svg" />
      </button>
      <input
        style="width: 39px; text-align: center; padding: 0px;"
        value={APP.grid.size}
        onInput={(e) => {
          const val = parseInt(e.target.value)
          APP.grid.size = clamp(val, 1, 10)
          VIEW.render()
        }}
        onWheel={() => {}}
        type="number"
      />
    </div>
  )
}

export const Header = () => {
  return <div class='h-40 bg-light bord-dark-b fl'>
    <div class="fl w-full">
      <div class="fl-1 fl">
        <div class="fl bord-dark-r rel w-40"
          onMouseLeave={() => {
            VIEW.file.open = false
            VIEW.render()
          }}>
          <button
            onClick={() => {
              VIEW.file.open = !VIEW.file.open
              VIEW.render()
            }}
            class="fl fl-center m-0 p-0 w-40">
            <img src="img/bars.svg" />
          </button>
          <div
            class="bg-light fl-column bord-dark abs z-5"
            style={`visibility: ${VIEW.file.open ? 'visible' : 'hidden'}; top: 10px; left: 10px;`}>
              <button
                onClick={() => {
                  VIEW.newCanvas.open = true
                  VIEW.file.open = false
                  VIEW.render()
                }}
                class="m-0 p-h-15 h-40 fl fl-center-y">
                <img src={`img/new.svg`} />
                <small class="bold p-h-10" style='text-transform: capitalize;'>New</small>
              </button>
              <button
                onClick={() => {
                  VIEW.downloadCanvas.open = true
                  VIEW.file.open = false
                  VIEW.render()
                }}
                class="m-0 p-h-15 h-40 fl fl-center-y">
                <img src={`img/download.svg`} />
                <small class="bold p-h-10" style='text-transform: capitalize;'>download</small>
              </button>
              <button
                onClick={() => {
                  VIEW.resizeCanvas.open = true
                  VIEW.resizeCanvas.width = 32
                  VIEW.resizeCanvas.height = 32
                  VIEW.render()
                }}
                class="m-0 p-h-15 h-40 fl fl-center-y">
                <img src={`img/resize.svg`} />
                <small class="bold p-h-10" style='text-transform: capitalize; width: max-content;'>Resize Canvas</small>
              </button>
          </div>
        </div>
        <div class="fl-1 fl" style="justify-content: flex-start;">
          <div class="fl" style="padding: 6px; gap: 5px;">
            <BrushSize />
            {(APP.tool === 'pencil' || APP.tool === 'eraser') && (
              <div class='fl' style="gap: 5px;">
                <button
                  title="Mirror X"
                  onClick={() => { VIEW.mirror.x = !VIEW.mirror.x; VIEW.render() }}
                  style="padding: 8px; width: 30px;"
                  class={`${VIEW.mirror.x ? 'bg-blue' : 'bg-dark'} button fl fl-center bord-dark b-r-2`}
                >
                  <img class="ptr-none" src="img/mirror-left.svg" />
                </button>
                <button
                  title="Mirror Y"
                  onClick={() => { VIEW.mirror.y = !VIEW.mirror.y; VIEW.render() }}
                  style="padding: 8px; width: 30px;"
                  class={`${VIEW.mirror.y ? 'bg-blue' : 'bg-dark'} button fl fl-center bord-dark b-r-2`}
                >
                  <img style="transform: rotate(90deg);" class="ptr-none" src="img/mirror-left.svg" />
                </button>
              </div>
            )}
            {(APP.tool === 'square' || APP.tool === 'circle') && (
              <div class='fl' style="gap: 5px;">
                <button
                  title="Shape Filled"
                  onClick={() => { VIEW.shape.filled = !VIEW.shape.filled; VIEW.render() }}
                  style="padding: 8px; width: 30px;"
                  class={`${VIEW.shape.filled ? 'bg-blue' : 'bg-dark'} button fl fl-center bord-dark b-r-2`}
                >
                  <img style="width: 13px; height: 13px;" class="ptr-none" src="img/shape-filled.svg" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div class='fl-1 fl fl-justify-center'>
        </div>
        <div class="fl-1 fl" style="justify-content: flex-end;">
          <CopyPaste />
          <UndoRedo/>
          <div class="fl bord-dark-l relative">
            <button
              class="button w-full h-full fl fl-center"
              onClick={() => { VIEW.canvasSettings = !VIEW.canvasSettings; VIEW.render(); }} >
              <img class='ptr-none' src={`img/settings.svg`} />
            </button>
            {VIEW.canvasSettings && <div class="abs bg-light bord-dark b-r-2" style="height: 49px; top: calc(100% + 5px); right: 4px; padding: 10px; z-index: 10;">
              <Grid />
            </div>}
          </div>
        </div>
      </div>
      <div class="fl fl-center m-0 p-0 w-40 bord-dark-l hide-at-850" style="max-width: 241px; min-width: 241px;">
        <a class='fl-1 h-full fl fl-center bord-dark-r' target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfLJsMhoVV7MRSrnARnLi-g93FYf6V1jFdp9XfMwATbYODLUg/viewform?usp=header">
          <small class="bold">Send Feedback</small>
        </a>
        <a class='h-full fl fl-center' target="_blank" href="https://www.youtube.com/@spritepaintapp" style={{ padding: '0px 12px'}}>
          <img src="img/youtube.svg" />
        </a>
      </div>
    </div>
  </div>

}