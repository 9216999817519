import { h, render, Component } from 'preact'
import { APP, VIEW, ENV, initCanvases, newData, MAX_FRAME_COUNT, resizeCanvas } from './state'
import { setupKeyListeners } from './keyboard'

// Components
import { Color } from './components/color'
import { Timeline } from './components/timeline'
import { Canvas, commitSelectionBuffer, enforceBounds, paintCanvas, resetLasso } from './components/canvas'
import { Header } from './components/header'
import { Toolbar } from './components/toolbar'
import { clamp, getFrameCount } from './utils'
import { state0point2 } from './state0p2'
import { DEFAULT_COLOR_PALETTE, NES_COLOR_PALETTE } from './constants/palettes'

const loadGifLibrary = async () => {
  return new Promise((resolve, reject) => {
    if (VIEW.gifLibraryLoaded) {
      resolve(); // If already loaded, resolve immediately
      return;
    }

    const script = document.createElement('script');
    script.src = '/gif.js'; // Load gif.js
    script.onload = () => {
      VIEW.gifLibraryLoaded = true; // Mark the library as loaded
      resolve();
    };
    script.onerror = () => reject(new Error('Failed to load gif.js'));
    document.body.appendChild(script);
  });
}

const downloadCanvas = async (e) => {
  const c = document.createElement('canvas')
  const ctx = c.getContext('2d')
  const height = APP.height * VIEW.downloadCanvas.size
  const width = APP.width * VIEW.downloadCanvas.size
  APP.frameCount = getFrameCount()

  await loadGifLibrary()

  if (VIEW.downloadCanvas.type === 'gif') {
    const frameRate = 1000 / APP.fps; // Frame delay in milliseconds, derived from a variable `APP.frameRate`
  
    c.width = width;
    c.height = height;
  
    ctx.webkitImageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;
  
    // Initialize GIF.js
    const gif = new GIF({
      workers: 2, // Use 2 workers for encoding
      quality: 1, // Quality setting (lower is better)
      width: width,
      height: height,
    });
  
    // Loop through each frame and add it to the GIF
    for (let frameI = 0; frameI < APP.frameCount; frameI++) {
      console.log(frameI)
      // Clear the canvas to avoid stacking frames
      ctx.clearRect(0, 0, c.width, c.height);

      // Draw each layer for the current frame
      APP.layers.forEach((layer) => {
        const drawingIndex = layer.clips[frameI]
        const drawing = APP.drawings[drawingIndex]
        if (drawingIndex > 0) {
          VIEW.canvasTemp.ctx.putImageData(drawing, 0, 0);
          ctx.drawImage(VIEW.canvasTemp.dom, 0, 0, c.width, c.height);
        }
      });

      // Add the current canvas frame to the GIF
      gif.addFrame(ctx, { copy: true, delay: frameRate });
    }
  
    // Once the GIF is finished, create a downloadable link
    gif.on('finished', (blob) => {
      VIEW.exporting = false
      VIEW.render()
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'animation.gif';
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  
    // Start rendering the GIF
    VIEW.exporting = true
    VIEW.render()
    gif.render();
  }
  
  if (VIEW.downloadCanvas.type === 'frame') {
    c.width = width
    c.height = height

    ctx.webkitImageSmoothingEnabled = false
    ctx.mozImageSmoothingEnabled = false
    ctx.imageSmoothingEnabled = false

    APP.layers.forEach(layer => {
      const drawingIndex = layer.clips[APP.frameActive]
      const drawing = APP.drawings[drawingIndex]
      if (drawingIndex > 0) {
        VIEW.canvasTemp.ctx.putImageData(drawing, 0, 0)
        ctx.drawImage(VIEW.canvasView.dom, 0, 0, c.width, c.height)
      }
    })

    const image = c.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    // e.target.setAttribute('href', image)
    
    const link = document.createElement('a');
    link.href = image;
    link.download = 'spritepaint.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (VIEW.downloadCanvas.type === 'spritesheet') {
    const totalWidth = APP.width * VIEW.downloadCanvas.size * APP.frameCount
    c.width = totalWidth
    c.height = height

    ctx.webkitImageSmoothingEnabled = false
    ctx.mozImageSmoothingEnabled = false
    ctx.imageSmoothingEnabled = false

    for (let frameI = 0; frameI < APP.frameCount; frameI++) {
      APP.layers.forEach(layer => {
        const drawingIndex = layer.clips[frameI]
        const drawing = APP.drawings[drawingIndex]
        if (drawingIndex > 0) {
          VIEW.canvasTemp.ctx.putImageData(drawing, 0, 0)
          ctx.drawImage(VIEW.canvasTemp.dom, frameI * width, 0, width, height)
        }
      })
    }

    const image = c.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    // e.target.setAttribute('href', image)
    const link = document.createElement('a');
    link.href = image;
    link.download = 'spritepaint.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (VIEW.downloadCanvas.type === 'numgrid') {
    c.width = APP.width
    c.height = APP.height

    ctx.webkitImageSmoothingEnabled = false
    ctx.mozImageSmoothingEnabled = false
    ctx.imageSmoothingEnabled = false

    APP.layers.forEach(layer => {
      VIEW.canvasTemp.ctx.putImageData(layer.frames[APP.frameActive], 0, 0)
      ctx.drawImage(VIEW.canvasView.dom, 0, 0, c.width, c.height)
    })

    const finalImage = ctx.getImageData(0, 0, c.width, c.height)

    const areRGBAsEqual = (c1, a, c2, b) => {
      return (
        c1[a + 0] === c2[b + 0] &&
        c1[a + 1] === c2[b + 1] &&
        c1[a + 2] === c2[b + 2] &&
        c1[a + 3] === c2[b + 3]
      )
    }

    const newCanvas = document.createElement('canvas')
    newCanvas.width = APP.width * 40
    newCanvas.height = (APP.height * 40) + 40
    const newCanvasCtx = newCanvas.getContext('2d')

    let index = 0

    let includedColors = []

    for (let i = 0; i < finalImage.data.length; i += 4) {
      let x = index % APP.width
      let y = Math.floor(index / APP.height)

      APP.palette.forEach((color, paletteIndex) => {
        if (areRGBAsEqual(color, 0, [finalImage.data[i + 0], finalImage.data[i + 1], finalImage.data[i + 2], finalImage.data[i + 3]], 0)) {
          newCanvasCtx.fillStyle = 'rgba(5, 5, 5, .3)'
          newCanvasCtx.font = '20px serif'
          newCanvasCtx.fillText(paletteIndex, (x * 40) + 15, ((y + 2) * 40) - 15)
          newCanvasCtx.strokeStyle = 'rgba(5, 5, 5, .2)'
          newCanvasCtx.strokeRect(x * 40, (y + 1) * 40, 40, 40)

          let includeColor = true
      
          includedColors.forEach(colorPrep => {
            if (areRGBAsEqual(colorPrep.color, 0, [finalImage.data[i + 0], finalImage.data[i + 1], finalImage.data[i + 2], finalImage.data[i + 3]], 0)) {
              includeColor = false
            }
          })

          if (includeColor) {
            includedColors.push({
              paletteIndex: paletteIndex,
              color: [finalImage.data[i + 0], finalImage.data[i + 1], finalImage.data[i + 2], finalImage.data[i + 3]]
            })
          }
        }
      })

      includedColors.forEach((includedColor, includedColorI)=> {
        newCanvasCtx.fillStyle = `rgba(${includedColor.color[0]}, ${includedColor.color[1]}, ${includedColor.color[2]}, 255)`
        newCanvasCtx.font = '20px serif';
        newCanvasCtx.fillText(includedColor.paletteIndex, (includedColorI * 80) + 15, 40);
        newCanvasCtx.fillRect((includedColorI * 80) + 40, 0, 40, 40)
      })

      index += 1
    }

    const image = newCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    e.target.setAttribute('href', image)
  }
}

const drawTimeline = () => {
  const DPR = 2
  const TILE_SIZE = 30
  const cw = APP.layers[0].clips.length
  const ch = APP.layers.length

  // Timeline Final
  VIEW.canvasTimeline.width = cw * TILE_SIZE * DPR
  VIEW.canvasTimeline.height = ch * TILE_SIZE * DPR
  VIEW.canvasTimeline.style.width = cw * TILE_SIZE + 'px'
  VIEW.canvasTimeline.style.height = ch * TILE_SIZE + 'px'
  const ctx = VIEW.canvasTimeline.getContext('2d', { willReadFrequently: true })

  // Timeline Temp
  VIEW.canvasTimelineTemp.width = cw * TILE_SIZE * 2
  VIEW.canvasTimelineTemp.height = ch * TILE_SIZE * 2
  const ctxTemp = VIEW.canvasTimelineTemp.getContext('2d', { willReadFrequently: true })

  // Timeline Thumbnail
  const ctxThumbnail = VIEW.canvasTimelineThumbnail.getContext('2d', { willReadFrequently: true })
  
  APP.layers.forEach((layer, li) => {
    layer.clips.forEach((clip, fi) => {
      const w = TILE_SIZE * DPR;
      const h = TILE_SIZE * DPR;
      const x = fi * w;
      const y = ((APP.layerCount - 1) * h) - (li * h);
      const b = 1;
      const padding = b * 2;
      const outline = 4;
      const activeClip = APP.clipActive;
      const activeFrame = APP.frameActive;
      const activeLayer = APP.layerActive;
  
      const hasLeftBoundary = fi === 0 || clip !== layer.clips[fi - 1] || clip === 0;
      const hasRightBoundary = fi === layer.clips.length - 1 || clip !== layer.clips[fi + 1] || clip === 0;

      // Base background rectangle
      ctx.fillStyle = 'rgba(33, 33, 33, 255)';
      ctx.fillRect(x, y, w, h);
  
      // Draw Gap
      ctx.fillStyle = 'rgba(50, 50, 50, 255)';
      ctx.fillRect(x, y, w - padding, h - padding);

      if (clip > 0 && fi === activeFrame) {
        if (li === activeLayer) {
          ctx.fillStyle = 'rgba(52, 152, 219, 255)';
        }
        // if (li !== activeLayer) {
        //   ctx.fillStyle = 'rgba(100, 100, 100, 255)';
        // }
        
        ctx.fillRect(
          hasLeftBoundary ? x + padding + outline : x,
          y + padding + outline,
          hasLeftBoundary && hasRightBoundary ? w - (padding * 5) - outline :
          (hasLeftBoundary || hasRightBoundary) ? w - (padding * 4) :
          w - padding,
          h - (padding * 5) - outline
        );
      }
  
      // Draw Clips
      if (clip > 0) {
        // Main fill area
        const frameImage = APP.drawings[clip]; // You need a function to retrieve the frame image
        ctxThumbnail.putImageData(frameImage, 0, 0)
        
        if (frameImage) {
          ctx.drawImage(
            VIEW.canvasTimelineThumbnail,
            0, 0, frameImage.width, frameImage.height, // Source (full image)
            hasLeftBoundary ? x + padding + outline : x, 
            y + padding + outline,
            hasLeftBoundary && hasRightBoundary ? w - (padding * 5) - outline :
            (hasLeftBoundary || hasRightBoundary) ? w - (padding * 4) :
            w - padding,
            h - (padding * 5) - outline
          );
        } else {
          ctx.fillStyle = 'rgb(100,100,100)'; // Fallback if no image found
          ctx.fillRect(
            hasLeftBoundary ? x + padding + outline : x,
            y + padding + outline,
            hasLeftBoundary && hasRightBoundary ? w - (padding * 5) - outline :
            (hasLeftBoundary || hasRightBoundary) ? w - (padding * 4) :
            w - padding,
            h - (padding * 5) - outline
          );
        }

        ctxTemp.clearRect(0,0,APP.width, APP.height)

        ctx.fillStyle = 'rgb(100,100,100)';
  
        // Draw Top and Bottom Boundaries
        let boundaryWidth = hasRightBoundary ? w - padding : w;
        ctx.fillRect(x, y, boundaryWidth, outline);
        ctx.fillRect(x, y + h - outline - padding, boundaryWidth, outline);
  
        // Draw Left and Right Boundaries
        if (hasLeftBoundary) ctx.fillRect(x, y, outline, h - padding);
        if (hasRightBoundary) ctx.fillRect(x + w - outline - padding, y, outline, h - padding);
      }
      
      let isMultiSelected = VIEW.multiSelectMode && VIEW.multiSelected.has(clip);
      let isActiveLayer = !VIEW.multiSelectMode && li === activeLayer;
      let isActiveFrame = isActiveLayer && clip === 0 && fi === activeFrame;
      let isActiveClip = isActiveLayer && clip > 0 && clip === activeClip;

      if (
        (clip === 0 && isActiveLayer)
        // || (clip === 0 && fi === activeFrame && !isActiveLayer)
      ) {
        ctx.fillStyle = 'rgba(100, 100, 100, 255)';
        ctx.fillRect(x, y, w - padding, h - padding);
      }
      
      if (isMultiSelected || isActiveFrame || isActiveClip) {
        if (clip > 0) {
          ctx.fillStyle = 'rgba(52, 152, 219, 255)';

          let boundaryWidth = hasRightBoundary ? w - padding : w;
          ctx.fillRect(x, y, boundaryWidth, outline);
          ctx.fillRect(x, y + h - outline - padding, boundaryWidth, outline);
  
          if (hasLeftBoundary) ctx.fillRect(x, y, outline, h - padding);
          if (hasRightBoundary) ctx.fillRect(x + w - outline - padding, y, outline, h - padding);
        } else {
          ctx.fillStyle = 'rgba(52, 152, 219, 255)';
          ctx.fillRect(x, y, w - padding, h - padding);
        }
      }
      
      // Draw Playhead
      if (activeFrame === fi) {
        ctx.fillStyle = 'rgba(33, 33, 33, 255)';
        ctx.fillRect(x - 3, y, 6, h);
        ctx.fillStyle = 'white';
        ctx.fillRect(x - 1, y, 2, h);
      }
    });
  });

  ctx.drawImage(VIEW.canvasTimelineTemp, 0, 0)
}

class View extends Component{
  componentDidMount () {
    VIEW.render = () => {
      this.setState({}, () => {
        VIEW.canvasTemp.ctx.clearRect(0, 0, APP.width, APP.height)
        VIEW.canvasFinal.ctx.clearRect(0, 0, APP.width, APP.height)
        VIEW.canvasView.ctx.clearRect(0, 0, APP.width, APP.height)

        APP.layers.forEach((layer, i) => {
          VIEW.canvasView.ctx.globalAlpha = 1

          if (layer.hidden) return

          // Onion skinning
          if (APP.layerActive === i && VIEW.onionSkinning && !VIEW.isPlaying) {
            const framesAhead = APP.onionSkin ? APP.onionSkin.framesAhead : 3;
            const framesBehind = APP.onionSkin ? APP.onionSkin.framesBehind : 3;
        
            VIEW.canvasView.ctx.globalAlpha = 0.5;
        
            for (let a = -framesBehind; a <= framesAhead; a++) {
                if (a === 0) continue; // Skip the current frame itself
        
                const frameIndex = APP.frameActive + a;
                if (!layer.clips[frameIndex]) continue;
        
                const index = layer.clips[frameIndex];
                const target = APP.drawings[index];
                VIEW.canvasTemp.ctx.putImageData(target, 0, 0);
                VIEW.canvasView.ctx.drawImage(VIEW.canvasTemp.dom, 0, 0);
            }
          }

          // Regular frame render
          VIEW.canvasView.ctx.globalAlpha = 1

          const index = layer.clips[APP.frameActive]
          const target = APP.drawings[index]

          for (let b = 0; b < 2; b++) { // For whatever reason safari makes me do this twice
            if (target) {
              // Target Canvas
              VIEW.canvasTemp.ctx.putImageData(target, 0, 0)
              VIEW.canvasView.ctx.drawImage(VIEW.canvasTemp.dom, 0, 0)
            }
            
            // Preview Canvas
            if (APP.layerActive === i) {
              VIEW.canvasTemp.ctx.putImageData(VIEW.canvasPreview.imgData, 0, 0)
              VIEW.canvasView.ctx.drawImage(VIEW.canvasTemp.dom, 0, 0)
            }
          }
        })
        
        // Selection Canvas
        VIEW.canvasTemp.ctx.putImageData(VIEW.canvasSelection.imgData, 0, 0)
        VIEW.canvasView.ctx.drawImage(VIEW.canvasSelection.dom, 0, 0)

        // Lasso Line
        VIEW.canvasTemp.ctx.putImageData(VIEW.canvasLasso.imgData, 0, 0)
        VIEW.canvasView.ctx.drawImage(VIEW.canvasLasso.dom, 0, 0)
        
        // Draw Timeline
        drawTimeline()
      })
    }

    initCanvases()

    this.funcs = { paintCanvas }

    // View control customization
    this.workspaceRef = document.querySelector('#workspace');
    this.canvasOuterScroll = document.querySelector('#canvas-outer-scroll')
    this.canvasInnerScroll = document.querySelector('#canvas-inner-scroll')
    this.timelineScroll = {
      isSyncingLeftScroll: false,
      isSyncingRightScroll: false,
      leftDiv: document.querySelector('#layers'),
      rightDiv: document.querySelector('#frames')
    }

    this.timelineScrollController()

    // Adding google analytics
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-144729452-1');
  }

  timelineScrollController () {
    this.timelineScroll.leftDiv.addEventListener('scroll', (e) => {
      if (!this.timelineScroll.isSyncingLeftScroll) {
        this.timelineScroll.isSyncingRightScroll = true
        this.timelineScroll.rightDiv.scrollTop = e.target.scrollTop
      }
      this.timelineScroll.isSyncingLeftScroll = false
    })
    
    this.timelineScroll.rightDiv.addEventListener('scroll', (e) => {
      if (!this.timelineScroll.isSyncingRightScroll) {
        this.timelineScroll.isSyncingLeftScroll = true
        this.timelineScroll.leftDiv.scrollTop = e.target.scrollTop
      }
      this.timelineScroll.isSyncingRightScroll = false
    })
  }

  calculateDynamicScaleBounds () {
    const workspaceRef = document.querySelector('#workspace');
    const { width, height } = workspaceRef.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
  
    const baseScale = Math.min(viewportWidth / width, viewportHeight / height);
    const maxScale = baseScale * 50; // Allow zoom up to 50x the base scale
    const minScale = baseScale * 0.5; // Allow zoom down to 10% of the base scale
  
    return { minScale, maxScale };
  };

  handleTransformCanvasStart (e) {
    e.preventDefault()
    
    const { left, top } = this.workspaceRef.getBoundingClientRect()
    const touch1 = e.touches[0]
    const touch2 = e.touches[1]
    const offsetX1 = touch1.clientX - left
    const offsetY1 = touch1.clientY - top
    const offsetX2 = touch2.clientX - left
    const offsetY2 = touch2.clientY - top
    
    const initialTouchDistance = Math.hypot(offsetX2 - offsetX1, offsetY2 - offsetY1)
    const initialTouchAngle = Math.atan2(offsetY2 - offsetY1, offsetX2 - offsetX1)
    const initialTouchMidpoint = {
        x: (offsetX1 + offsetX2) / 2,
        y: (offsetY1 + offsetY2) / 2
    }

    // Set initial values in the workspace state
    VIEW.workspace.initialTouchDistance = initialTouchDistance
    VIEW.workspace.initialTouchAngle = initialTouchAngle
    VIEW.workspace.initialTouchMidpoint = initialTouchMidpoint
    VIEW.workspace.startingScale = VIEW.workspace.scale
    VIEW.workspace.previousMidpoint = initialTouchMidpoint // Save the initial midpoint
    VIEW.workspace.previousAngle = initialTouchAngle // Save the initial anglehttp:
    VIEW.render()
  }
  
  handleTransformCanvasMove (e) {
    VIEW.workspace.transformDelta += 1
    
    const { left, top } = this.workspaceRef.getBoundingClientRect()
    const touch1 = e.touches[0]
    const touch2 = e.touches[1]
    const offsetX1 = touch1.clientX - left
    const offsetY1 = touch1.clientY - top
    const offsetX2 = touch2.clientX - left
    const offsetY2 = touch2.clientY - top

    const currentTouchDistance = Math.hypot(offsetX2 - offsetX1, offsetY2 - offsetY1)
    VIEW.workspace.currentTouchDistance = currentTouchDistance
    const currentMidpoint = {
      x: (offsetX1 + offsetX2) / 2,
      y: (offsetY1 + offsetY2) / 2,
    }
    const { translate, rotate } = VIEW.workspace

    // Calculate new scale using dynamic bounds
    const deltaScale = currentTouchDistance / VIEW.workspace.initialTouchDistance;
    const { minScale, maxScale } = this.calculateDynamicScaleBounds();
    let newScale = Math.max(minScale, Math.min(maxScale, VIEW.workspace.startingScale * deltaScale));


    // Apply a damping factor to smooth the scaling
    const DAMPING_FACTOR = 0.1 // You can adjust this value
    newScale = VIEW.workspace.scale * (1 - DAMPING_FACTOR) + newScale * DAMPING_FACTOR

    const canvasMidpointBeforeScaling = {
      x: (currentMidpoint.x - translate.x) / VIEW.workspace.scale,
      y: (currentMidpoint.y - translate.y) / VIEW.workspace.scale,
    }

    // Calculate the position of the midpoint in the canvas coordinate system after scaling
    const canvasMidpointAfterScaling = {
      x: canvasMidpointBeforeScaling.x * newScale,
      y: canvasMidpointBeforeScaling.y * newScale,
    }

    const deltaMovement = {
      x: currentMidpoint.x - VIEW.workspace.previousMidpoint.x,
      y: currentMidpoint.y - VIEW.workspace.previousMidpoint.y,
    }

    // Adjust the translation to keep the midpoint fixed during scaling
    let newTranslate = {
      x: currentMidpoint.x - canvasMidpointAfterScaling.x,
      y: currentMidpoint.y - canvasMidpointAfterScaling.y,
    }

    // Calculate rotation
    const currentAngle = Math.atan2(touch2.clientY - touch1.clientY, touch2.clientX - touch1.clientX)
    // const deltaRotate = currentAngle - VIEW.workspace.previousAngle
    const deltaRotate = 0
    const newRotate = rotate + (deltaRotate * (180 / Math.PI)) // Convert to degrees

    // Adjust translation to keep the midpoint fixed during rotation
    const radians = deltaRotate
    const sin = Math.sin(radians)
    const cos = Math.cos(radians)

    const dx = currentMidpoint.x - newTranslate.x
    const dy = currentMidpoint.y - newTranslate.y

    newTranslate = {
      x: currentMidpoint.x - (dx * cos - dy * sin) + deltaMovement.x,
      y: currentMidpoint.y - (dx * sin + dy * cos) + deltaMovement.y,
    }

    // Update state with the new scale, translation, and rotation
    VIEW.workspace.scale = newScale
    VIEW.workspace.translate = newTranslate
    VIEW.workspace.rotate = newRotate

    // Update the previous midpoint and initial touches for the next touch move event
    VIEW.workspace.previousMidpoint = currentMidpoint
    VIEW.workspace.previousAngle = currentAngle // Update the previous angle

    VIEW.render()
  }

  // touchCount(e, { single = () => {}, double = () => {} } = {}) {
  //   // Check the number of active touches
  //   const touchCount = e.touches.length;

  //   if (this.touchTimeout) clearTimeout(this.touchTimeout);

  //   this.touchTimeout = setTimeout(() => {
  //     if (touchCount === 1) {
  //       VIEW.workspace.touchCount = touchCount
  //       single(e)
  //     } else if (touchCount === 2) {
  //       // VIEW.workspace.touchCount = touchCount
  //       double(e)
  //     }
  //   }, 50); // Use the delay to stabilize detection
  // }

  touchCount(e, { single = () => {}, double = () => {} } = {}) {
    // Define a threshold for detecting palm touches
    const PALM_THRESHOLD = 40;
  
    // Filter touches that are likely from a palm
    const validTouches = Array.from(e.touches).filter(
      (touch) => touch.radiusX < PALM_THRESHOLD && touch.radiusY < PALM_THRESHOLD
    );
  
    // Get the number of valid touches
    const touchCount = validTouches.length;
  
    if (this.touchTimeout) clearTimeout(this.touchTimeout);
  
    this.touchTimeout = setTimeout(() => {
      if (touchCount === 1) {
        VIEW.workspace.touchCount = touchCount;
        single(e);
      } else if (touchCount === 2) {
        double(e);
      }
    }, 50); // Use the delay to stabilize detection
  } 

  onGestureDown (e) {
    if (navigator.maxTouchPoints > 0 && e.type === 'mousedown') return
    if (VIEW.isPlaying) return

    VIEW.window.mouseDown = true

    const prepareRequest = () => {
      VIEW.window.request = e.target.dataset.request || ''
      VIEW.window.startX = (e.pageX === undefined) ? e.touches[0].pageX : e.pageX
      VIEW.window.startY = (e.pageY === undefined) ? e.touches[0].pageY : e.pageY
      VIEW.window.prevX = (e.pageX === undefined) ? e.touches[0].pageX : e.pageX
      VIEW.window.prevY = (e.pageY === undefined) ? e.touches[0].pageY : e.pageY
      VIEW.window.currX = (e.pageX === undefined) ? e.touches[0].pageX : e.pageX
      VIEW.window.currY = (e.pageY === undefined) ? e.touches[0].pageY : e.pageY

      if (VIEW.window.request) this.funcs[VIEW.window.request]('start')
    }

    const mode = 'touch'

    if (e.touches) {
      if (mode === 'touch') {
        this.touchCount(
          e,
          {
            single: (e) => {
              VIEW.workspace.gesture = 'draw'
              prepareRequest()
            },
            double: (e) => {
              VIEW.workspace.gesture = 'transform'
              e.preventDefault()
              this.handleTransformCanvasStart(e)
            }
          }
        )
      }

      if (mode === 'stylus') {
        const hasStylusTouch = (event) => Array.from(event.touches).some(t => t.touchType === "stylus");
        console.log(e.touches)

        if (hasStylusTouch(e)) {
          VIEW.workspace.gesture = 'draw'
          prepareRequest()
        }
        else if (e.touches.length >= 2 && hasStylusTouch(e)) {
          VIEW.workspace.gesture = 'draw'
          prepareRequest()
        }
        else if (e.touches.length >= 2 && !hasStylusTouch(e)) {
          VIEW.workspace.gesture = 'transform'
          e.preventDefault()
          this.handleTransformCanvasStart(e)
        }
      }
      
    } else {
      if (VIEW.moveCanvas) return

      VIEW.workspace.gesture = 'draw'
      prepareRequest()
    }
  }
  
  onGestureDrag (e) {
    if (navigator.maxTouchPoints > 0 && e.type === 'mousemove') return
    if (VIEW.isPlaying) return

    // Prevent pinch-to-zoom when more than one touch is detected
    if (e.touches && e.touches.length > 1) {
      e.preventDefault();
    }

    if (VIEW.moveCanvas) {
      VIEW.window.prevX = VIEW.window.currX
      VIEW.window.prevY = VIEW.window.currY
      VIEW.window.currX = (e.pageX === undefined) ? e.touches[0].pageX : e.pageX
      VIEW.window.currY = (e.pageY === undefined) ? e.touches[0].pageY : e.pageY

      const workspaceRef = document.querySelector('#workspace');
      const canvasViewRef = document.querySelector('#canvas-view');
      const { width, height } = workspaceRef.getBoundingClientRect();
      const canvasViewBounds = canvasViewRef.getBoundingClientRect();
      const { scale, translate } = VIEW.workspace;
      
      const newTranslate = {
        x: translate.x - (VIEW.window.prevX - VIEW.window.currX),
        y: translate.y - (VIEW.window.prevY - VIEW.window.currY),
      };
  
      // Update translate while enforcing bounds
      VIEW.workspace.translate = enforceBounds(newTranslate, width, height, scale, canvasViewBounds);
      VIEW.render();

      return
    }

    if (VIEW.workspace.gesture === 'draw') {
      VIEW.window.prevX = VIEW.window.currX
      VIEW.window.prevY = VIEW.window.currY
      VIEW.window.currX = (e.pageX === undefined) ? e.touches[0].pageX : e.pageX
      VIEW.window.currY = (e.pageY === undefined) ? e.touches[0].pageY : e.pageY

      if (VIEW.window.request) this.funcs[VIEW.window.request]('resume')
    }

    if (VIEW.workspace.gesture === 'transform') {
      e.preventDefault()
      this.handleTransformCanvasMove(e)
    }
  }

  onGestureEnd (e) {
    if (VIEW.isPlaying) return

    if (VIEW.window.request) this.funcs[VIEW.window.request]('end')
    
    VIEW.workspace.gesture = ''

    VIEW.window.request = ''
    VIEW.window.mouseDown = false
    VIEW.window.startX = 0
    VIEW.window.startY = 0
    VIEW.window.prevX = 0
    VIEW.window.prevY = 0
    VIEW.window.currX = 0
    VIEW.window.currY = 0
  }

  onGestureHover (e) {
    if (navigator.maxTouchPoints > 0) return
    if (e.touches) return
    
    if (VIEW.isPlaying) return

    VIEW.window.prevX = VIEW.window.currX
    VIEW.window.prevY = VIEW.window.currY
    VIEW.window.currX = (e.pageX === undefined) ? e.touches[0].pageX : e.pageX
    VIEW.window.currY = (e.pageY === undefined) ? e.touches[0].pageY : e.pageY

    if (e.target.dataset.hover) this.funcs[e.target.dataset.hover]('hover')
  }
  
  dragOrHover (e) {
    if (VIEW.window.mouseDown) {
      this.onGestureDrag(e)
    } else {
      this.onGestureHover(e)
    }
  }

  render () {
    return (
      <div
        class='h-full relative'
        onMouseDown={(e) => { if (e.which === 1) this.onGestureDown(e); }}
        onTouchStart={(e) => { this.onGestureDown(e); }}
        onTouchMove={(e) => {
          if (e.target.id === 'canvas-container') {
            e.preventDefault();  
          }

          this.dragOrHover(e);
        }}
        onTouchEnd={(e) => { this.onGestureEnd(e) }}
        onTouchCancel={(e) => { this.onGestureEnd(e) }}
        onMouseMove={(e) => { this.dragOrHover(e) }}
        onMouseUp={(e) => { this.onGestureEnd(e) }}
        onMouseLeave={(e) => { this.onGestureEnd(e) }}
      >
        {/* {ENV === 'DEV' && <div id='debugger' class='abs' style='right: 0px; left: 0px; width: 100px; height: 100px; background: white; z-index: 1000;'>
          <canvas />
        </div>} */}
        <Header />
        <div id="center-column" class='fl' style='height: calc(100% - 40px);'>
          <Toolbar />
          <div id="center" class='fl-column'>
            <Canvas />
            <Timeline />
          </div>
          <div id='right-bar' class='bg-light bord-dark-l fl-column' style="max-width: 241px; min-width: 241px;">
            {/* <div class='bord-dark-b fl-column overflow'>
              <div class='h-30 bg-mid bord-dark-b fl fl-center-y p-h-10'>
                <small><b>Tool</b></small>
              </div>
              <div class='fl-1 overflow'>
                <div class="fl fl-center p-10">
                  <small style="width: 150px; font-size: 11px;">Brush Size</small>
                  <div class='fl-1 select'>
                    <select
                      onInput={(e) => {
                        VIEW.brushSize = parseInt(e.target.value)
                      }}
                      value={VIEW.brushSize}
                      class="w-full">
                        {
                          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((size, i) => {
                            return <option value={i}>{size}</option>
                          })
                        }
                    </select>
                  </div>
                </div>
              </div>
            </div> */}
            <Color />
            <div class='bord-dark-b fl-column overflow' style='min-height: 199px; max-height: 199px;'>
              <div class='h-30 bg-mid bord-dark-b fl fl-center-y p-h-10'>
                <small><b>History</b></small>
              </div>
              <div class='fl-1 overflow'>
                {
                  VIEW.undo.map((entry, i) => {
                    return <button class={`p-h-10 h-30 w-full txt-left fl fl-center-y no-ptr ${VIEW.undoPos === i - 1 ? 'bg-xlight' : ''}`} >
                      <img width='10' height='10' style='margin-right: 10px;' src={`img/${entry.icon}`} />
                      <small style='text-transform: capitalize; font-size: 11px;'><b>{entry.action}</b></small>
                    </button>
                  })
                }
              </div>
            </div>
          </div>
        </div>
        {VIEW.newCanvas.open && <div class="abs top left w-full h-full fl fl-justify-center" style="z-index: 10;">
          <div class="w-full overflow-hidden" style="max-width: 300px; margin-top: 175px;">
            <div class="fl fl-center bg-mid bord-dark p-v-5" style='border-top-right-radius: 5px; border-top-left-radius: 5px;'><small><b>New Canvas</b></small></div>
            <div class="p-10 bg-light bord-dark-l bord-dark-r bord-dark-b" style='border-bottom-right-radius: 5px; border-bottom-left-radius: 5px;'>
              <div class="m-5 p-v-5 fl fl-column" style="gap: 16px;">
                <div class='fl fl-center'>
                  <small style="width: 55px;" class="bold">Presets</small>
                  <div class='fl fl-1' style="gap: 8px;">
                    <button
                      onClick={() => {
                        VIEW.newCanvas.w = 32; 
                        VIEW.newCanvas.h = 32;
                        VIEW.render()
                      }}
                      class='p-5 fl-1 bg-dark b-r-2 bord-dark'
                    >32x32</button>  
                    <button
                      onClick={() => {
                        VIEW.newCanvas.w = 50; 
                        VIEW.newCanvas.h = 50;
                        VIEW.render()
                      }}
                      class='p-5 fl-1 bg-dark b-r-2 bord-dark'
                    >50x50</button>  
                    <button
                      onClick={() => {
                        VIEW.newCanvas.w = 64; 
                        VIEW.newCanvas.h = 64;
                        VIEW.render()
                      }}
                      class='p-5 fl-1 bg-dark b-r-2 bord-dark'
                    >64x64</button>  
                  </div>
                </div>
                <div class="fl fl-center">
                  <div class="fl fl-center w-full">
                    <small style="width: 55px;" class="bold">Width</small>
                    <input onInput={(e) => { VIEW.newCanvas.w = parseInt(e.target.value) }} value={VIEW.newCanvas.w} class='fl-1' type="number" style="margin-right: 8px;" />
                    <small style="width: 55px;" class="bold">Height</small>
                    <input onInput={(e) => { VIEW.newCanvas.h = parseInt(e.target.value) }} value={VIEW.newCanvas.h} class='fl-1' type="number" />
                  </div>
                </div>
              </div>
              <div class="fl" style="padding-top: 5px;">
                <button
                  onClick={() => {
                    VIEW.newCanvas.open = false
                    VIEW.newCanvas.w = 32; 
                    VIEW.newCanvas.h = 32;
                    VIEW.render()
                  }}
                  class="b-r-2 bold p-5 w-full bg-red m-5">Cancel</button>
                <button
                  onClick={() => {
                    const newWidth = clamp(VIEW.newCanvas.w, 2, 500)
                    const newHeight = clamp(VIEW.newCanvas.h, 2, 500)
                    newData(newWidth, newHeight)
                    VIEW.newCanvas.open = false
                    VIEW.newCanvas.w = 32; 
                    VIEW.newCanvas.h = 32;
                    VIEW.render()
                  }}
                  class="b-r-2 bold p-5 w-full bg-green m-5">Confirm</button>
              </div>
            </div>
          </div>
        </div>}
        {VIEW.downloadCanvas.open && <div class="abs top left w-full h-full fl fl-center-x" style="z-index: 10;">
          <div class="w-full" style="max-width: 300px; overflow: hidden; margin-top: 175px;">
              <div class="fl fl-center bg-mid bord-dark p-v-5" style='border-top-right-radius: 5px; border-top-left-radius: 5px;'><small class="bold">Download</small></div>
              <div class="p-10 bg-light bord-dark-l bord-dark-r bord-dark-b" style='border-bottom-right-radius: 5px; border-bottom-left-radius: 5px;'>
                <div class="m-5 p-v-5">
                  <div class="fl fl-center">
                    <small class="bold" style="width: 100px;">Type</small>
                    <div class='fl-1 select'>
                      <select
                        onInput={(e) => {
                          VIEW.downloadCanvas.type = e.target.value
                        }}
                        value={VIEW.downloadCanvas.type}
                        id="config-download-size" class="w-full">
                          <option value="gif">GIF</option>
                          <option value="frame">Frame</option>
                          <option value="spritesheet">Spritesheet</option>
                          {/* <option value="numgrid">Number Grid</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="m-5 p-v-5">
                  <div class="fl fl-center">
                    <small class="bold" style="width: 100px;">Size</small>
                    <div class='fl-1 select'>
                      <select
                        onInput={(e) => {
                          VIEW.downloadCanvas.size = parseInt(e.target.value)
                        }}
                        value={VIEW.downloadCanvas.size}
                        id="config-download-size" class="w-full">
                          <option value="1">1x</option>
                          <option value="2">2x</option>
                          <option value="4">4x</option>
                          <option value="8">8x</option>
                          <option value="16">16x</option>
                          <option value="32">32x</option>
                          <option value="64">64x</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="fl" style="padding-top: 5px;">
                  <button
                    onClick={() => {
                      VIEW.downloadCanvas.open = false
                      VIEW.render()
                    }}
                    class="b-r-2 bold p-5 w-full bg-red m-5">Cancel</button>
                  <button
                    onClick={(e) => {
                      if (!VIEW.exporting) { // Prevent multiple clicks while exporting
                        downloadCanvas(e)
                      }
                    }}
                    disabled={VIEW.exporting} // Disable the button while exporting
                    class={`b-r-2 bold p-5 w-full m-5 ${VIEW.exporting ? 'bg-mid' : 'bg-green'}`} // Change background when disabled
                  >
                    {VIEW.exporting ? (
                      <span>
                        <span class="spinner"></span>
                      </span>
                    ) : (
                      'Download'
                    )}
                  </button>

                  {/* <button
                    onClick={(e) => {
                      downloadCanvas(e)
                    }}
                    class="w-full m-5 clickable" download="pixel-art.png" style="display: inline-block;">
                    <button class="b-r-2 bold p-5 w-full bg-green no-ptr">Download</button>
                  </button> */}
                </div>
              </div>
          </div>
        </div>}
        {VIEW.announcements.open && <div class="abs top left w-full h-full fl fl-center-x" style="z-index: 10; padding: 0px 16px;">
          <div class="w-full" style="max-width: 500px; overflow: hidden; margin-top: 150px;">
              <div class="fl bg-mid bord-dark" style='border-top-right-radius: 5px; border-top-left-radius: 5px; overflow: hidden;'>
                <small class="bold p-v-5 p-h-10">Version Upgrade Notice</small>
                <button
                  class='bg-red'
                  style="margin-left: auto;"
                  onClick={() => {
                    VIEW.announcements.open = false
                    VIEW.render()
                  }}
                >X</button>
              </div>
              <div class="p-10 bg-light bord-dark-l bord-dark-r bord-dark-b fl fl-column" style='padding: 15px 10px; gap: 10px; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px;'>
                <p>{VIEW.announcements.updates[0]}</p>
                <ul>
                  {
                    VIEW.announcements.updates.slice(1, VIEW.announcements.length).map(update => {
                      return <li style="margin: 0; padding-bottom: 4px;" class="txt-white">{update}</li>
                    })
                  }
                </ul>

              </div>
              
          </div>
        </div>}
        {VIEW.resizeCanvas.open && <div class="abs top left w-full h-full fl fl-center-x" style="z-index: 10; padding: 0px 16px;">
          <div class="" style="max-width: 500px; overflow: hidden; margin-top: 150px;">
              <div class="fl bg-mid bord-dark" style='border-top-right-radius: 5px; border-top-left-radius: 5px; overflow: hidden;'>
                <small class="bold p-v-5 p-h-10">Resize Canvas</small>
                <button
                  class='bg-red'
                  style="margin-left: auto;"
                  onClick={() => {
                    VIEW.resizeCanvas.open = false
                    VIEW.render()
                  }}
                >X</button>
              </div>
              <div class="p-10 bg-light bord-dark-l bord-dark-r bord-dark-b fl fl-column" style='padding: 15px 25px; gap: 10px; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px;'>
                <div class='fl p-5' style="gap: 16px;">
                  <div class="fl fl-column" style="gap: 4px;">
                    <div class='fl fl-center'>
                      <small style="width: 60px;" class="bold">Width</small>
                      <input value={VIEW.resizeCanvas.width} onInput={(e) => { VIEW.resizeCanvas.width = parseInt(e.target.value) }} min="0" max="500" style="width: 90px;" type="number" />
                    </div>
                    <div class='fl fl-center'>
                      <small style="width: 60px;" class="bold">Height</small>
                      <input value={VIEW.resizeCanvas.height} onInput={(e) => { VIEW.resizeCanvas.height = parseInt(e.target.value) }} min="0" max="500" style="width: 90px;" type="number" />
                    </div>
                    <div class="checkbox">
                      <label class='fl fl-center'>
                        <small class='fl-1 bold'>Resize contents</small>
                        <input onClick={(e) => { VIEW.resizeCanvas.scale = e.target.checked }} type="checkbox" />
                      </label>
                    </div>
                  </div>
                  <div class='fl'>
                    <small style="min-width: 60px; padding-top: 6px;" class="bold">Anchor</small>
                    <div class='w-full fl fl-column' style="gap: 4px;">
                      <div class='fl' style="justify-content: space-between; gap: 4px;">
                        {
                          ['top-left', 'top-center', 'top-right'].map((str) => {
                            return (
                              <button
                                onClick={() => {
                                  VIEW.resizeCanvas.anchor = str;
                                  VIEW.render()
                                }}
                                class={`bord-dark no-hover ${VIEW.resizeCanvas.anchor === str ? 'bg-blue' : 'bg-dark'} b-r-2`}
                                style="width: 27px; height: 27px;"
                              ></button>
                            )
                          })
                        }
                      </div>
                      <div class='fl' style="justify-content: space-between; gap: 4px;">
                        {
                          ['middle-left', 'center', 'middle-right'].map((str) => {
                            return (
                              <button
                                onClick={() => {
                                  VIEW.resizeCanvas.anchor = str;
                                  VIEW.render()
                                }}
                                class={`bord-dark no-hover ${VIEW.resizeCanvas.anchor === str ? 'bg-blue' : 'bg-dark'} b-r-2`}
                                style="width: 27px; height: 27px;"
                              ></button>
                            )
                          })
                        }
                      </div>
                      <div class='fl' style="justify-content: space-between; gap: 4px;">
                        {
                          ['bottom-left', 'bottom-center', 'bottom-right'].map((str) => {
                            return (
                              <button
                                onClick={() => {
                                  VIEW.resizeCanvas.anchor = str;
                                  VIEW.render()
                                }}
                                class={`bord-dark no-hover ${VIEW.resizeCanvas.anchor === str ? 'bg-blue' : 'bg-dark'} b-r-2`}
                                style="width: 27px; height: 27px;"
                              ></button>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fl" style="padding-top: 5px;">
                  <button
                    onClick={() => {
                      VIEW.resizeCanvas.open = false
                      VIEW.render()
                    }}
                    class="b-r-2 bold p-5 w-full bg-red m-5">Cancel</button>
                  <button
                    onClick={(e) => {
                      resizeCanvas()
                      VIEW.resizeCanvas.open = false
                      VIEW.resizeCanvas.anchor = 'center'
                      VIEW.resizeCanvas.w = 64
                      VIEW.resizeCanvas.h = 64
                      VIEW.render()
                    }}
                    class={`b-r-2 bold p-5 w-full m-5 bg-green`} // Change background when disabled
                  >
                    Confirm
                  </button>
                </div>
              </div>
              
          </div>
        </div>}
      </div>
    )
  }
}

const migrateDataToZeroPointTwo = (data) => {
  console.log("Checking for data migration...");

  // Default values for new fields
  const defaultClipActive = 1;
  const defaultDrawings = [null];
  const defaultOnionSkin = {
    framesAhead: 3,
    framesBehind: 3,
  }
  const defaultGrid = {
    enabled: false,
    size: 1,
  }
  const defaultFps = 12
  const defaultPaletteActive = 0
  const defaultPalettes = [
    [...DEFAULT_COLOR_PALETTE],
  ]

  VIEW.announcements.open = true

  if (!data.version) {
    console.log("Adding missing field: version");
    data.version = 0.2;
    VIEW.announcements.updates.push(`New SpritePaint Update Alert! New features include:`)
    VIEW.announcements.updates.push(`Introduced a basic Lasso tool`)
    VIEW.announcements.updates.push(`Mirror setting to pencil: Horizontal and Vertical`)
    VIEW.announcements.updates.push(`Added filled shapes to square and circle`)
    VIEW.announcements.updates.push(`Copy and Paste drawing selections within the frame or across frames`)
    VIEW.announcements.updates.push(`Make a canvas of any size and resize it mid project`)
    VIEW.announcements.updates.push(`Pan artboard by holding down spacebar`)
  }

  // Ensure `clipActive` exists
  if (!data.clipActive) {
      console.log("Adding missing field: clipActive");
      data.clipActive = defaultClipActive;
      VIEW.announcements.updates.push('Extend and Trim the frame length of your drawings')
  }

  // Ensure `drawings` exists
  if (!data.drawings) {
      console.log("Adding missing field: drawings");
      data.drawings = defaultDrawings;
  }

  if (!data.onionSkin) {
    console.log("Adding missing field: onion skin");
    data.onionSkin = defaultOnionSkin;
    VIEW.announcements.updates.push('Control the frame length of your onion skins')
  }

  if (!data.fps) {
    data.fps = defaultFps;
    VIEW.announcements.updates.push('Edit and persist the control of your fps')
  }

  if (!data.grid) {
    console.log("Adding missing field: grid");
    data.grid = defaultGrid;
    VIEW.announcements.updates.push('Enable and disable a basic grid')
  }

  if (!data.palettes) {
    const paletteClone = data.palette.map(color => [...color])
    data.palettes = [paletteClone]
    data.paletteActive = 0
    data.palette = data.palettes[data.paletteActive]
    VIEW.announcements.updates.push('Save, edit, and delete color palettes')
  }

  // if (!data.paletteActive) {
  //   data.paletteActive = defaultPaletteActive
  // }
  // TODO: if there is a saved color palette, add that to the list of palettes and make that one the active palette, otherwise default

  // Convert frames into drawings and update clips
  if (data.layers) {
      data.layers.forEach((layer, layerIndex) => {
          if (!layer.clips) {
              console.log(`Adding missing field: clips to layer ${layer.name}`);
              layer.clips = new Array(MAX_FRAME_COUNT).fill(0);
          }

          if (layer.frames) {
              layer.frames.forEach((imageData, frameIndex) => {
                if (imageData instanceof ImageData) {
                  // Store ImageData in `drawings` and reference it in `clips`
                  let hasContent = false
                  for (let i = 0; i < imageData.data.length; i++) {
                    if (imageData.data[i] !== 0) { // Check any non-transparent pixel
                        hasContent = true;
                        break; // Exit loop early
                    }
                  }             

                  if (hasContent) {
                      const drawingIndex = data.drawings.length;
                      data.drawings.push(imageData);
                      layer.clips[frameIndex] = drawingIndex;
                      console.log('found image data', layerIndex, frameIndex)
                  } else {
                      layer.clips[frameIndex] = 0; // Mark as empty
                      console.log('empty', layerIndex, frameIndex)
                  }
                }
              });
          }
      });
  }

  return data;
};

function deserializeState(serializedState) {
  const parsed = JSON.parse(serializedState, (key, value) => {
    if (value && value._type === "ImageData") {
      return new ImageData(
        new Uint8ClampedArray(value.data),
        value.width,
        value.height
      );
    }
    return value;
  });

  return parsed
}

const testMigration = (func) => {
  const snapshot = deserializeState(state0point2)
  console.log(VIEW, APP)
  console.log('BEFORE', snapshot)

  localforage.setItem('pixel-art-app', snapshot).then(function(value) {
    console.timeEnd('startwrite')
    func()
  }).catch(function(err) {
    console.log(err);
  });
}


const loadData = ({ onLoaded, onError }) => {
  //console.time('startRead')

  const setupLoadData = () => {
    localforage.getItem('pixel-art-app').then((stored) => {
      if (!stored) return
      
      if (!stored.version || stored.version < 0.2) { // force
        migrateDataToZeroPointTwo(stored)
      }

      //console.log('AFTER', stored)
      //console.timeEnd('startRead')

      for (const key in stored) {
        APP[key] = stored[key]
      }

      onLoaded()
    }).catch(function(err) {
      console.log(err)
      onError()
    });
  }

  setupLoadData()

  // testMigration(() => {
  //   setupLoadData()
  // })
}

const saveData = (e) => {
  if (e && e.target && e.target.id === 'clear-data') {
    return
  }

  setTimeout(() => {
    console.time('startwrite')
    localforage.setItem('pixel-art-app', APP).then(function(value) {
      console.timeEnd('startwrite')
    }).catch(function(err) {
      console.log(err);
    });
  }, 50)
}

const onProgramStart = () => {
  console.log('Program started.')

  newData(64, 64, true)
  render(<View />, document.body)
  
  loadData({
    onLoaded: () => {
      initCanvases()
      VIEW.render()
    },
    onError: () => {}
  })

  setupKeyListeners()
  
  window.addEventListener('keyup', saveData)
  window.addEventListener('mouseup', saveData)
  window.addEventListener('touchend', saveData)
  document.addEventListener('change', saveData);
}

window.addEventListener('load', onProgramStart)
window.addEventListener('contextmenu', function(event) {
  event.preventDefault();
});

if (ENV === 'PROD') {
  window.addEventListener('beforeunload', (event) => {
    event.returnValue = `Are you sure you want to leave?`;
    commitSelectionBuffer()
    localforage.setItem('pixel-art-app', APP).then(function(value) {
      console.timeEnd('startwrite')
    }).catch(function(err) {
      console.log(err);
    });
  });
}

