import { addToUndo, freezeFrameData } from "./undo-redo"
import { APP } from './state'

export const clamp = (value, min, max) => Math.max(min, Math.min(max, value));

export const getFrameCount = () => {
  let lastContentFrame = 0;
  APP.layers.forEach(layer => {
    for (let i = layer.clips.length - 1; i >= 0; i--) {
      if (layer.clips[i] !== 0) {
        
        lastContentFrame = Math.max(lastContentFrame, i);
        break; // No need to check further for this layer
      }
    }
  });

  return lastContentFrame + 1
}

export const newDrawing = () => {
  // get the new index
  const newIndex = APP.drawings.length

  addToUndo('Insert', 'New Drawing')
  freezeFrameData('new', 'undo', newIndex)

  // add a new drawing to the images bank
  const newImageData = new ImageData(APP.width, APP.height)
  APP.drawings.push(newImageData)
  // update the existing reference to whatever the new index is instead of 0
  APP.layers[APP.layerActive].clips[APP.frameActive] = newIndex // Update reference
  // update the active clip index to the new drawing index
  APP.clipActive = newIndex

  freezeFrameData('new', 'redo', newIndex)

  return newIndex
}

// export const deepCloneLayer = (layer) => {
//   return {
//     name: layer.name, // Strings are immutable, so no need to clone
//     hidden: layer.hidden, // Booleans are immutable, no need to clone
//     frames: [...layer.frames], // Clone array (assuming it's a flat array)
//     clips: [...layer.clips] // Clone array of numbers (primitives, so safe to spread)
//   };
// }

export const deepCloneLayers = (layers) => {
  return layers.map(layer => ({
      name: layer.name,
      hidden: layer.hidden,
      frames: [],
      clips: [...layer.clips] // Spread operator to create a new array
  }));
}


export const cloneLasso = (lasso) => {
  return {
      isSelecting: lasso.isSelecting,
      canSelect: lasso.canSelect,
      active: lasso.active,
      points: lasso.points.map(point => ({ x: point.x, y: point.y })) // Deep clone array of objects
  };
}

export const cloneImageData = (imageData) => {
  const copy = new ImageData(imageData.width, imageData.height)
  copy.data.set(imageData.data)
  return copy
}

export const RGBtoHSL = (rgb) => {
  // Make r, g, and b fractions of 1
  let r = rgb[0] / 255
  let g = rgb[1] / 255
  let b = rgb[2] / 255

  // Find greatest and smallest channel values
  let cmin = Math.min(r, g, b)
  let cmax = Math.max(r, g, b)
  let delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0

  if (delta === 0) {
    h = 0
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6
  } else if (cmax === g) {
    h = (b - r) / delta + 2
  } else {
    h = (r - g) / delta + 4
  }

  h = Math.round(h * 60)

  // Make negative hues positive behind 360°
  if (h < 0) h += 360

  l = (cmax + cmin) / 2

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return [ Math.floor(h), Math.floor(s), Math.floor(l), 255 ]
}

export const HSLtoRGB = (hsl) => {
  const h = hsl[0]
  const s = hsl[1] / 100
  const l = hsl[2] / 100

  let c = (1 - Math.abs(2 * l - 1)) * s
  let x = c * (1 - Math.abs((hsl[0] / 60) % 2 - 1))
  let m = l - c / 2
  let r = 0
  let g = 0
  let b = 0

  if (h >= 0 && h < 60) {
    r = c; g = x; b = 0
  } else if (h >= 60 && h < 120) {
    r = x; g = c; b = 0
  } else if (h >= 120 && h < 180) {
    r = 0; g = c; b = x
  } else if (h >= 180 && h < 240) {
    r = 0; g = x; b = c
  } else if (h >= 240 && h < 300) {
    r = x; g = 0; b = c
  } else if (h >= 300 && h < 360) {
    r = c; g = 0; b = x
  }

  r = Math.round((r + m) * 255)
  g = Math.round((g + m) * 255)
  b = Math.round((b + m) * 255)

  return [ r, g, b, 255 ]
}