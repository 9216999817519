import { undo, redo } from './undo-redo'
import { setTool } from './components/toolbar'
import { copyDrawing, deleteSelected, pasteDrawing } from './components/canvas'
import { VIEW } from './state'

const keyMap = {
  metaKey: {
    z: undo,
    c: copyDrawing,
    v: pasteDrawing
  },
  ctrlKey: {
    z: undo,
    c: copyDrawing,
    v: pasteDrawing
  },
  shiftKey: {
    metaKey: {
      z: redo
    },
    ctrlKey: {
      z: redo
    }
  },
  b: () => setTool('pencil'),
  e: () => setTool('eraser'),
  u: () => setTool('line'),
  g: () => setTool('fill'),
  l: () => setTool('eye-dropper'),
  v: () => setTool('move'),
  delete: deleteSelected, // Single "Delete" key on macOS
  backspace: deleteSelected // Single "Backspace" key on Windows
}

// TODO: replace with optional chaining when buble is upgraded
export const setupKeyListeners = () => {
  window.addEventListener('keydown', (e) => {
    if (e.target.tagName === 'INPUT') return

    const key = e.key.toLowerCase()

    if (e.key === " " || e.keyCode === 32) {
      VIEW.moveCanvas = true
      e.preventDefault(); // Prevents page scrolling when spacebar is pressed
    }
    
    if (key === 'backspace' || key === 'delete') {
      e.preventDefault(); // Prevent accidental navigation (e.g., going back in browser)
      if (keyMap[key]) {
        keyMap[key]();
      }
      return;
    }

    // Check for combinations
    if (key === 'z') {
      if (e.metaKey && e.shiftKey) {
        if (keyMap.shiftKey.metaKey.z) {
          keyMap.shiftKey.metaKey.z() // Shift + Meta + Z
        }
      } else if (e.ctrlKey && e.shiftKey) {
        if (keyMap.shiftKey.ctrlKey.z) {
          keyMap.shiftKey.ctrlKey.z() // Shift + Ctrl + Z
        }
      } else if (e.metaKey) {
        if (keyMap.metaKey.z) {
          keyMap.metaKey.z() // Meta + Z
        }
      } else if (e.ctrlKey) {
        if (keyMap.ctrlKey.z) {
          keyMap.ctrlKey.z() // Ctrl + Z
        }
      }
    } else if (e.metaKey && keyMap.metaKey[key]) {
      if (keyMap.metaKey[key]) {
        keyMap.metaKey[key]() // Meta + any other key
      }
    } else if (e.ctrlKey && keyMap.ctrlKey[key]) {
      if (keyMap.ctrlKey[key]) {
        keyMap.ctrlKey[key]() // Ctrl + any other key
      }
    } else if (!e.metaKey && !e.ctrlKey && !e.shiftKey && keyMap[key]) {
      if (keyMap[key]) {
        keyMap[key]() // Single keypress
      }
    }
  })

  window.addEventListener('keyup', (e) => {
    if (e.key === " " || e.keyCode === 32) {
      VIEW.moveCanvas = false
      e.preventDefault(); // Prevents page scrolling when spacebar is pressed
    }
  })
}
