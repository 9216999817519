import { h, render } from 'preact'
import { APP, MAX_FRAME_COUNT, VIEW } from '../state'
import { addToUndo, freezeFrameData, freezeLayerData } from '../undo-redo'
import { commitSelectionBuffer, copyDrawing, pasteDrawing, resetLasso, setupRedo, setupUndo } from './canvas';
import { getFrameCount, newDrawing } from '../utils';

const setUndoRedo = (undoAction, redoAction) => {
  VIEW.currUndoRef.undo = undoAction;
  VIEW.currUndoRef.redo = redoAction;
};

export const completeLasso = () => {
  commitSelectionBuffer()
  resetLasso()
  if (VIEW.lasso.active) {
    APP.tool = 'pencil'  
  }
  VIEW.lasso.active = false
}

const setTargetCanvas = (frame, layer) => {
  let setupLasso = false
  if (VIEW.lasso.points.length) {
    setupLasso = true
    setupUndo('Print Lasso')
    completeLasso()
  }

  APP.frameActive = frame
  APP.layerActive = layer
  APP.clipActive = APP.layers[layer].clips[frame]
  APP.tool = 
    APP.clipActive === 0 &&
    (APP.tool === 'lasso' || APP.tool === 'move' || APP.tool === 'eye-dropper')
    ? 'pencil' : APP.tool

  if (setupLasso) {
    setupRedo()
  }

  VIEW.render()

  // if (frame >= 0 && frame < APP.frameCount && layer >= 0 && layer <= APP.layerCount) {

  // } else {
  //   console.error('frame and layer number out of range', frame, layer)
  // }
}

const normalizeFrameLengths = (type) => {
  let setupLasso = false
  if (VIEW.lasso.points.length) {
    setupUndo('Print Lasso')
    completeLasso()
  }

  // // Step 2: Find the last non-zero content across all layers
  // let lastContentFrame = 0;
  // APP.layers.forEach(layer => {
  //   for (let i = layer.clips.length - 1; i >= 0; i--) {
  //     if (layer.clips[i] !== 0) {
        
  //       lastContentFrame = Math.max(lastContentFrame, i);
  //       break; // No need to check further for this layer
  //     }
  //   }
  // });

  // // Step 3: Define target length (last content + 1 free frame)
  // const targetLength = lastContentFrame + 1;

  // // Step 4: Extend or trim each layer’s clips array
  // APP.layers.forEach(layer => {
  //   let missingLength = targetLength - layer.clips.length;
  //   if (missingLength > 0) {
  //     layer.clips = layer.clips.concat(new Array(missingLength).fill(0)); // Extend
  //   }
  //   else {
  //     layer.clips.length = targetLength; // Trim
  //   }
  // });

  // APP.frameActive = Math.min(APP.frameActive, targetLength - 1);

  // // Step 5: Update global frame count
  // APP.frameCount = targetLength;
  
  if (setupLasso) {
    setupRedo()
  }
};

// const shiftNumber = (arr, num, direction) => {
//   let blocked = true; // Default to true, assume no move is possible

//   if (direction === "left") {
//     for (let i = 1; i < arr.length; i++) {
//       if (arr[i] === num) {
//         if (arr[i - 1] === 0) {
//           arr[i - 1] = num;
//           arr[i] = 0;
//           blocked = false; // Movement happened, so it's not blocked
//         } else if (arr[i - 1] === num) {
//           blocked = true; // Blocked due to duplicate merge scenario
//         }
//       }
//     }
//   } else if (direction === "right") {
//     for (let i = arr.length - 2; i >= 0; i--) {
//       if (arr[i] === num) {
//         if (arr[i + 1] === 0) {
//           arr[i + 1] = num;
//           arr[i] = 0;
//           blocked = false; // Movement happened, so it's not blocked
//         } else if (arr[i + 1] === num) {
//           blocked = true; // Blocked due to duplicate merge scenario
//         }
//       }
//     }
//   }

//   return blocked;
// }

const shiftNumber = (arr, num, direction) => {
  let index = arr.indexOf(num);
  console.log(APP.drawings, index)
  if (index === -1) return true; // No move possible if num not found

  const step = direction === "left" ? -1 : 1;
  const limit = direction === "left" ? 0 : arr.length - 1;

  return false

  // while (index >= 0 && index < arr.length) {
  //   let nextIndex = index + step;

  //   // If out of bounds, stop
  //   if (nextIndex < 0 || nextIndex >= arr.length) break;

  //   if (arr[nextIndex] === 0) {
  //     // Move if the next space is empty
  //     [arr[index], arr[nextIndex]] = [arr[nextIndex], arr[index]];
  //   } else if (arr[nextIndex] !== num) {
  //     // Swap with an adjacent clip group
  //     let groupStart = nextIndex;
  //     while (groupStart !== limit && arr[groupStart - step] === arr[nextIndex]) {
  //       groupStart -= step;
  //     }

  //     let movingGroup = arr.slice(index, index + step); // Group of moving number
  //     let targetGroup = arr.slice(groupStart, nextIndex + step); // Adjacent clip group

  //     // Swap groups
  //     arr.splice(groupStart, targetGroup.length, ...movingGroup);
  //     arr.splice(index, movingGroup.length, ...targetGroup);
  //     return false; // Move completed
  //   } else {
  //     // Blocked by same number
  //     return true;
  //   }

  //   index += step;
  // }

  return false;
};


const duplicateDrawing = (clipRefIndex) => {
  const drawings = APP.drawings

  // create new image data of current clip
  const copy = new ImageData(APP.width, APP.height);
  const clip = drawings[clipRefIndex]
  copy.data.set(clip.data);
  
  // push image array and store index reference
  drawings.push(clip)
  const index = drawings.length - 1 // get frame index of layer

  return index
}

const splitActiveClip = () => {
  const currFrame = APP.frameActive
  const clipArray = APP.layers[APP.layerActive].clips
  const clipRefIndex = clipArray[currFrame]

  // cant split at the very start of the clip
  if (clipArray[currFrame - 1] !== clipRefIndex) {
    console.log('cant split start of clip')
    return
  }

  const newIndex = duplicateDrawing(clipRefIndex)
  
  // between index and end of clip, replace with new index reference
  for (let i = currFrame; i < clipArray.length; i++) {
    clipArray[i] = newIndex
    if (clipArray[i + 1] !== clipRefIndex) break
  }

  VIEW.render()
}

const setLayersUndo = (str, str2) => {
  addToUndo(str, str2)
  freezeLayerData('undo')
}

const setLayersRedo = () => {
  freezeLayerData('redo')
}

// FRAME FUNCTIONS
const insert = () => {
  if (VIEW.isPlaying) return
  
  completeLasso()

  const layer = APP.layerActive;
  const frame = APP.frameActive;
  const clips = APP.layers[layer].clips;

  const isGap = clips[frame] === 0;
  const lastFrame = frame === clips.length - 1;

  if (lastFrame) return false
  if (clips[clips.length - 1] > 0) return // content has hit frame limit

  // let hasContent = false;
  // for (let y = 0; y < APP.layers.length; y++) {
  //   if (APP.layers[y].clips[frame]) hasContent = true;
  // }

  // Prevent insertion if it's the last frame and it's an empty one
  // if (lastFrame && !hasContent) return false;

  // Prevent insertion if not last frame but coming from an empty frame
  // if (!lastFrame && isGap) return false;

  // if we're at the end of the timeline and already added a blank space
  // if (frame === clips.length - 2 && clips[clips.length - 1] === 0) {
  //   return false
  // }

  setLayersUndo('Insert', 'Frame')

  let targetX = frame; // Start from the current frame
  let foundTransition = false;

  if (isGap) {
    targetX = frame
  } else {
    // Find the nearest transition point to insert a new frame
    for (let x = frame; x < clips.length - 1; x++) {
      if (clips[x] !== clips[x + 1]) {
        targetX = x;
        foundTransition = true;
        break;
      }
    }

    // If no transition is found, set targetX to the last frame
    if (!foundTransition) {
      targetX = clips.length - 1;
    }
  }


  clips.splice(targetX + 1, 0, 0); // Insert 0 after clip transition
  clips.pop()

  // insert at gap 0
  // insert in middle of clip
  
  // if (frame === clips.length - 1) {
  //   // Insert 0 in the appropriate position across layers
  //   for (let y = 0; y < APP.layers.length; y++) {   
  //     if (y === layer) {
  //       APP.layers[y].clips.splice(targetX + 1, 0, 0); // Insert 0 after clip transition
  //     } else {
  //       APP.layers[y].clips.splice(clips.length, 0, 0); // Extend at the end
  //     }
  //   }
  // }
  
  // inserting gap should shift clips forward until final clips hit the end of the timeline
  // then insert empty spaces in all columsn to move clips forward into

  // APP.frameCount += 1;
  APP.frameActive = targetX + 1; // Set new active frame to inserted 0
  APP.clipActive = 0;

  setLayersRedo('Insert Frame');
  VIEW.render();

  return true
};

const extend = () => {
  if (VIEW.isPlaying) return

  const layer = APP.layerActive
  const frame = APP.frameActive
  const clip = APP.clipActive
  const clips = APP.layers[layer].clips
  
  const isGap = clips[frame] === 0;

  if (isGap) return
  if (clips[clips.length - 1] > 0) return // content has hit frame limit

  setLayersUndo('Extend', 'Frame')

  let targetX = frame; // Start from the current frame
  let foundTransition = false;

  // Find the nearest transition point to insert a new frame
  for (let x = frame; x < clips.length - 1; x++) {
    if (clips[x] !== clips[x + 1]) {
      targetX = x;
      foundTransition = true;
      break;
    }
  }

  // If no transition is found, set targetX to the last frame
  if (!foundTransition) {
    targetX = clips.length - 1;
  }

  // clips.splice(targetX + 1, 0, clip); // Insert 0 after clip transition
  // clips.pop()

  if (clips[targetX + 1] === 0) {
    // Extend into an empty space (0), just update the next frame
    clips[targetX + 1] = clip;
  } else {
    // Extend into a clip, push everything up (splice)
    clips.splice(targetX + 1, 0, clip);
    clips.pop(); // Maintain the total frame length
  }
  
  APP.frameActive += 1
  APP.frameCount += 1
  
  normalizeFrameLengths()

  setLayersRedo()

  VIEW.render()  
}

const trim = () => {
  if (VIEW.isPlaying) return

  const layer = APP.layerActive
  const frame = APP.frameActive
  const clip = APP.clipActive
  const clips = APP.layers[layer].clips

  if (clip === 0) return // ignore gaps
  if (clips[frame + 1] !== clip && clips[frame - 1] !== clip) return // ignore single frame clips

  setLayersUndo('Trim', 'Frame')

  let targetX = frame; // Start from the current frame
  let foundTransition = false;

  // Find the nearest transition point to insert a new frame
  for (let x = frame; x < clips.length - 1; x++) {
    if (clips[x] !== clips[x + 1]) {
      targetX = x;
      foundTransition = true;
      break;
    }
  }

  // If no transition is found, set targetX to the last frame
  if (!foundTransition) {
    targetX = clips.length - 1;
  }

  if (clips[frame - 1] === clip) {
    APP.frameActive -= 1
  } 

  clips.splice(targetX, 1, 0)


  // for (let x = frame; x < clips.length; x++) {
  //   if (clips[x + 1] !== clip) {
  //     clips[x] = 0
  //     break
  //   }
  // }

  normalizeFrameLengths()

  setLayersRedo()

  VIEW.render()
}

const deleteFrame = () => {
  if (VIEW.isPlaying) return

  const layer = APP.layerActive;
  const frame = APP.frameActive;
  const clip = APP.clipActive;
  const clips = APP.layers[layer].clips;

  // Block delete if all clips from `frame` onward are 0
  if (clips.slice(frame).every(c => c === 0)) return;
  
  // Prevent deletion of the very first frame and clip
  if (clips.length === 0 && layer === 0 && frame === 0 && clip === 0) return;

  // Move active frame back if deleting last frame
  if (frame === clips.length - 1) {
    APP.frameActive = Math.max(0, frame - 1);
  }

  // Setup Undo
  addToUndo('Delete');
  freezeFrameData('delete', 'undo', clip)

  // Process Delete
  if (clip === 0) {
    clips.splice(frame, 1);
    clips.push(0)
    normalizeFrameLengths();
  } else {
    // Remove all references to the clip
    APP.layers.forEach(layer => {
      for (let i = 0; i < layer.clips.length; i++) {
        if (layer.clips[i] === clip) {
          layer.clips[i] = 0; // Mark deleted clip as empty
        } else if (layer.clips[i] > clip) {
          layer.clips[i] -= 1; // Shift references down
        }
      }
    });

    APP.drawings.splice(clip, 1); // Remove the image data
    normalizeFrameLengths();
  }
  APP.clipActive = clips[APP.frameActive] || 0; // Ensure valid clipActive

  // Setup Redo
  freezeFrameData('delete', 'redo', clip)

  // Render
  VIEW.render();
};

const groupIntoChunks = (arr, target) => {
  let result = [];
  let currentGroup = [];
  let swapFromPos = -1;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === 0) {
      // If there's a non-zero group, push it first
      if (currentGroup.length > 0) {
        result.push(currentGroup);
        currentGroup = []; // Reset group
      }
      // Each zero gets its own array
      result.push([0]);
    } else {
      // If it's the same as the last non-zero, continue grouping
      if (currentGroup.length === 0 || arr[i] === currentGroup[0]) {
        currentGroup.push(arr[i]);
      } else {
        // Push previous group and start a new one
        result.push(currentGroup);
        currentGroup = [arr[i]];
      }
    }
  }

  // Push the last non-zero group if it exists
  if (currentGroup.length > 0) {
    result.push(currentGroup);
  }

  for (let i = 0; i < result.length; i++) {
    if (result[i][0] === target) {
      swapFromPos = i
      break
    } 
  }

  return { result, swapFromPos };
}

const swapWithNeighbor = (arr, i, direction) => {
  if (direction === "left" && i > 0) {
    [arr[i], arr[i - 1]] = [arr[i - 1], arr[i]];
  } else if (direction === "right" && i < arr.length - 1) {
    [arr[i], arr[i + 1]] = [arr[i + 1], arr[i]];
  }
}

const moveFrame = (dir) => {
  if (VIEW.isPlaying) return

  if (dir !== 'right' && dir !== 'left') {
    console.error("Invalid direction for moveFrame");
    return;
  }

  if (APP.clipActive === 0) return;

  const frame = APP.frameActive;
  const layer = APP.layerActive;
  const clip = APP.clipActive;
  const clips = APP.layers[layer].clips;
  
  const { result, swapFromPos } = groupIntoChunks(clips, clip)
  
  let canSwap = false
  if (dir === 'right') {
    if (swapFromPos < result.length - 1) {
      canSwap = true;
    }
    // else {
    //   result.push([0]);
    //   canSwap = true;
    // }
  } else if (dir === 'left' && swapFromPos > 0) {
    canSwap = true;
  }
  
  let assigned = false
  let targetFrameActive = -1
  let offset = 0
  const start = clips.indexOf(clip)
  const curr = frame
  offset = curr - start

  if (canSwap) {
    swapWithNeighbor(result, swapFromPos, dir)
    // reassign original array after swap
    setLayersUndo((dir === 'right' ? 'Up' : 'Down'), 'Frame');
    let i = 0
    result.forEach(chunk => {
      chunk.forEach(entry => {
        if (!assigned && entry === clip) {
          assigned = true
          targetFrameActive = i 
        }
        clips[i] = entry
        i += 1
      })
    })
    APP.frameActive = targetFrameActive + offset
    normalizeFrameLengths()
    setLayersRedo();
  }

  VIEW.render()

};

// const cloneFrame = () => {
//   if (APP.clipActive === 0) return
  
//   copyDrawing()
//   const inserted = insert()
//   if (inserted) {
//     newDrawing()
//     pasteDrawing()
//   }
// }

// export const copyDrawingSingle = () => {
//   VIEW.copy.canPaste = true

//   const layers = APP.layers
//   const selectedClipsSet = (VIEW.multiSelected && VIEW.multiSelected.size > 0) 
//     ? VIEW.multiSelected 
//     : new Set([APP.clipActive]);
  
//   if (selectedClipsSet.size === 0) return

//   let minStart = Infinity;
//   let maxEnd = -Infinity;
//   let minLayer = Infinity;
//   let maxLayer = -Infinity;
//   let extractedLayers = [];

//   // Step 1: Determine min/max bounds for selection across all layers
//   layers.forEach((layer, layerIndex) => {
//     let hasSelectedClip = false;

//     layer.clips.forEach((clipID, frameIndex) => {
//       if (selectedClipsSet.has(clipID) && clipID !== 0) {
//         minStart = Math.min(minStart, frameIndex);
//         maxEnd = Math.max(maxEnd, frameIndex);
//         hasSelectedClip = true;
//       }
//     });
    
//     if (hasSelectedClip) {
//       minLayer = Math.min(minLayer, layerIndex);
//       maxLayer = Math.max(maxLayer, layerIndex);
//     }
//   });

//   if (minStart === Infinity) return; // No valid clips selected

//   let newClipArrayLength = maxEnd - minStart + 1;

//   // Step 2: Extract only the selected layers
//   for (let layerIndex = minLayer; layerIndex <= maxLayer; layerIndex++) {
//     let layer = layers[layerIndex];
//     let newClipRow = new Array(newClipArrayLength).fill(0); // Initialize row with 0s
//     let hasData = false;

//     for (let frameIndex = minStart; frameIndex <= maxEnd; frameIndex++) {
//       let clipID = layer.clips[frameIndex];

//       if (selectedClipsSet.has(clipID)) {
//         let newFrameIndex = frameIndex - minStart; // Normalize to 0-based timeline
//         newClipRow[newFrameIndex] = clipID; // Assign clip to its new position
//         hasData = true;
//       }
//     }

//     // Only store layers that contain selected clips
//     if (hasData) {
//       extractedLayers.push(newClipRow);
//     }
//   }

//   VIEW.copy.minLayer = minLayer
//   VIEW.copy.maxLayer = maxLayer
//   VIEW.copy.minStart = minStart
//   VIEW.copy.maxEnd = maxEnd
//   VIEW.copy.extract = extractedLayers
//   VIEW.multiSelectMode = false
//   VIEW.render()
// }

// export const pasteDrawingSingle = () => {
//   if (!VIEW.copy.canPaste) return

//   const targetLayers = APP.layers;
//   const targetLayerStart = APP.layerActive;
//   const targetFrameStart = APP.frameActive;
//   const extract = VIEW.copy.extract;

//   let pasteWidth = VIEW.copy.maxEnd - VIEW.copy.minStart + 1;
//   let pasteHeight = VIEW.copy.maxLayer - VIEW.copy.minLayer + 1;

//   let sourceY = 0;
//   const clipDuplicates = {}; // Cache to store duplicated clips

//   console.log(extract)

//   // Ensure targetLayers is large enough
//   for (let y = targetLayerStart; y < targetLayerStart + pasteHeight; y++) {
//     console.log(targetLayerStart)
//     if (!targetLayers[y]) {
//       targetLayers[y] = { name: `New Layer ${y + 1}`, hidden: false, frames: [], clips: [] };
//     }

//     const clips = targetLayers[y].clips;

//     let sourceX = 0;

//     for (let x = targetFrameStart; x < targetFrameStart + pasteWidth; x++) {
//       let clipID = extract[sourceY] && extract[sourceY][sourceX];

//       if (clipID && clipID !== 0) { 
//         // Check if this clip ID has already been duplicated
//         if (!clipDuplicates[clipID]) {
//           clipDuplicates[clipID] = duplicateDrawing(clipID); // Store the duplicated clip
//         }

//         // clips[x] = clipDuplicates[clipID]; // overwrite, Use the cached duplicate
//         clips.splice(x, 0, clipDuplicates[clipID]);   // Insert instead of overwrite by shifting elements forward
//       } else {
//         clips.splice(x, 0, 0);   // Insert instead of overwrite by shifting elements forward
//       }

//       sourceX += 1;
//     }

//     sourceY += 1;
//   }

//   normalizeFrameLengths()

//   VIEW.render()
// }

// LAYER FUNCTIONS

export const insertLayer = () => {
  if (VIEW.isPlaying) return 
  if (APP.layerCount >= 30) return

  addToUndo('Insert', 'Layer')
  freezeLayerData('undo')

  const newLayer = {
    name: `Layer ${APP.layers.length + 1}`,
    hidden: false,
    // frames: Array.from({ length: MAX_FRAME_COUNT }, () => new ImageData(APP.width, APP.height)),
    clips: Array.from({ length: MAX_FRAME_COUNT}, () => 0),
  };
  
  APP.clipActive = 0
  APP.layers.splice(APP.layerActive + 1, 0, newLayer);
  APP.layerCount += 1;
  APP.layerActive += 1;

  freezeLayerData('redo')
  
  VIEW.render();
};

export const deleteLayer = () => {
  if (VIEW.isPlaying) return
  if (APP.layerCount <= 1) {
    console.warn("Cannot delete the only layer.");
    return;
  }

  addToUndo('Delete Layer')
  freezeLayerData('undo')

  // Remove the active layer
  APP.layers.splice(APP.layerActive, 1);
  const targetLayer = APP.layerActive - 1 < 0 ? 0 : APP.layerActive - 1
  APP.clipActive = APP.layers[targetLayer].clips[APP.frameActive]
  APP.layerActive = targetLayer
  APP.layerCount = APP.layers.length
  
  normalizeFrameLengths()
  freezeLayerData('redo')

  VIEW.render();
};

export const cloneLayer = () => {
  // const clonedLayer = deepCopyLayer(APP.layers[APP.layerActive]); // Deep copy the current layer
  // const prevActiveLayer = APP.layerActive;

  // addToUndo('Clone Layer')
  // freezeLayerData('undo')

  // // Insert the cloned layer
  // APP.layers.splice(APP.layerActive + 1, 0, clonedLayer);
  // APP.layerCount += 1;
  // APP.layerActive += 1;

  // VIEW.render();
};

// Move the active layer up in the stack
export const moveLayerUp = () => {
  if (VIEW.isPlaying) return
  if (APP.layerActive >= APP.layerCount - 1) return; // Cannot move further up

  addToUndo('Up', 'Layer')
  freezeLayerData('undo')

  // Swap the active layer with the one above it
  const temp = APP.layers[APP.layerActive];
  APP.layers[APP.layerActive] = APP.layers[APP.layerActive + 1];
  APP.layers[APP.layerActive + 1] = temp;
  APP.layerActive += 1;

  freezeLayerData('redo')

  VIEW.render();
};

// Move the active layer down in the stack
export const moveLayerDown = () => {
  if (VIEW.isPlaying) return

  if (APP.layerActive === 0) return; // Cannot move further down

  addToUndo('Down', 'Layer')
  freezeLayerData('undo')

  // Swap the active layer with the one below it
  const temp = APP.layers[APP.layerActive];
  APP.layers[APP.layerActive] = APP.layers[APP.layerActive - 1];
  APP.layers[APP.layerActive - 1] = temp;
  APP.layerActive -= 1;

  freezeLayerData('redo')

  VIEW.render();
};

const layersEditHidden = (i) => {
  if (VIEW.isPlaying) return

  APP.layers[i].hidden = !APP.layers[i].hidden
  VIEW.render()
}

const nextFrame = () => {
  if (VIEW.isPlaying) return

  if (VIEW.lasso.points.length) {
    setupUndo('Print Lasso')
    completeLasso()
  }

  APP.frameActive = (APP.frameActive + 1) % APP.frameCount
  APP.clipActive = APP.layers[APP.layerActive].clips[APP.frameActive]
  VIEW.render()
}

const lastFrame = () => {
  if (VIEW.isPlaying) return

  if (VIEW.lasso.points.length) {
    setupUndo('Print Lasso')
    completeLasso()
  }
  
  APP.frameActive = APP.frameActive - 1 === -1 ? APP.frameCount - 1 : APP.frameActive - 1
  APP.clipActive = APP.layers[APP.layerActive].clips[APP.frameActive]
  VIEW.render()
}

let lastTime = 0;

// const scheduler = (timestamp) => {
//   if (!VIEW.isPlaying) return; // Stop if playback is toggled off

//   if (!lastTime) lastTime = timestamp;
//   const elapsed = timestamp - lastTime;
//   const frameDuration = 1000 / APP.fps;

//   if (elapsed >= frameDuration) {
//     lastTime = timestamp;
    
//     APP.frameActive = (APP.frameActive + 1) % APP.frameCount;
//     APP.clipActive = APP.layers[APP.layerActive].clips[APP.frameActive];
//     VIEW.render();
//   }

//   VIEW.timerID = requestAnimationFrame(scheduler);
// };

// const togglePlay = () => {
//   APP.frameCount = getFrameCount();
//   if (APP.frameCount === 1) return;

//   if (VIEW.isPlaying) {
//     cancelAnimationFrame(VIEW.timerID);
//     VIEW.isPlaying = false;
//   } else {
//     lastTime = 0; // Reset timestamp tracking
//     VIEW.isPlaying = true;
//     requestAnimationFrame(scheduler);
//   }

//   VIEW.render();
// };

const scheduler = (timestamp) => {
  if (!VIEW.isPlaying) return; // Stop immediately if toggled off

  if (!lastTime) lastTime = timestamp;
  const elapsed = timestamp - lastTime;
  const frameDuration = 1000 / APP.fps;

  if (elapsed >= frameDuration) {
    lastTime = timestamp;
    APP.frameActive = (APP.frameActive + 1) % APP.frameCount;
    APP.clipActive = APP.layers[APP.layerActive].clips[APP.frameActive];
    VIEW.render();
  }

  // Ensure the next frame is only requested if still playing
  if (VIEW.isPlaying) {
    VIEW.timerID = requestAnimationFrame(scheduler);
  }
};

const togglePlay = () => {
  if (VIEW.lasso.points.length) {
    setupUndo('Print Lasso')
    completeLasso()
  }

  APP.frameCount = getFrameCount();
  if (APP.frameCount === 1) return;

  if (VIEW.isPlaying) {
    cancelAnimationFrame(VIEW.timerID);
    VIEW.timerID = null; // Reset animation frame tracking
    VIEW.isPlaying = false;
  } else {
    lastTime = 0; // Reset timestamp tracking
    VIEW.isPlaying = true;
    VIEW.timerID = requestAnimationFrame(scheduler);
  }

  VIEW.render();
};



const handleOnionSkin = (i) => {
  const { frameActive } = APP;

  if (i === frameActive) return

  if (i > frameActive + APP.onionSkin.framesAhead) {
    // Selecting out of range or within range (ahead)
    APP.onionSkin.framesAhead = Math.min(5, i - frameActive);
  } else if (i >= frameActive && i <= frameActive + APP.onionSkin.framesAhead) {
    APP.onionSkin.framesAhead = Math.min(5, i - frameActive - 1);
  }

  // FIX THIS
  if (i < frameActive - APP.onionSkin.framesBehind) {
    // Selecting out of range (behind) → Snap to the selected frame
    APP.onionSkin.framesBehind = Math.min(5, frameActive - i);
  } else if (i <= frameActive && i >= frameActive - APP.onionSkin.framesBehind) {
    // Selecting within range (behind) → Snap to one after
    APP.onionSkin.framesBehind = Math.min(5, frameActive - i - 1);
  }

  VIEW.render()
}

const FrameOps = () => {
  return (
    <div class='fl'>
      <button
        title="Insert Frame"
        // onClick={() => { if (!VIEW.isPlaying && APP.frameCount < 50) timelineManager({ type: 'frame', request: 'insert', requestType: 'new' }) }}
        onClick={() => { 
          insert()
          // if (VIEW.frameMode) {
          //   insertWholeFrame()
          // } else {
          //   // insertFrame()
          //   splitActiveClip()
          // }
        }}
        class='w-30 fl fl-center bord-dark-r bord-dark-l'>
        <img src={`img/insert.svg`} />
      </button>
      <button
        title="Move Frame Backward"
        onClick={() => {
          moveFrame('left')
          // if (VIEW.frameMode) {
          //   moveWholeFrameLeft()
          // } else if (VIEW.multiSelectMode) {
          //   moveSelectedFramesLeft()
          // } else {
          //   moveFrameLeft()
          // }
        }}
        class='w-30 fl fl-center bord-dark-r'>
        <img src={`img/up.svg`} style='transform: rotate(-90deg);'/>
      </button>
      <button
        title="Move Frame Forward"
        onClick={() => { 
          moveFrame('right')
          // if (VIEW.frameMode) {
          //   moveWholeFrameRight()
          // } else if (VIEW.multiSelectMode) {
          //   moveSelectedFramesRight()
          // } else {
          //   moveFrameRight()
          // }
        }}
        class='w-30 fl fl-center bord-dark-r'>
        <img src={`img/down.svg`} style='transform: rotate(-90deg);' />
      </button>
      <button
        title="Trim Frame Length"
        onClick={() => { 
          trim()
          // if (VIEW.frameMode) {
          //   deleteWholeFrame()
          // } else {
          //   deleteFrame()
          // }
        }}
        class='w-30 fl fl-center bord-dark-r'>
        <img src={`img/trim.svg`} />
      </button>
      <button
        title="Extend Frame Length"
        onClick={() => { 
          extend()
          // if (VIEW.frameMode) {
          //   deleteWholeFrame()
          // } else {
          //   deleteFrame()
          // }
        }}
        class='w-30 fl fl-center bord-dark-r'>
        <img src={`img/extend.svg`} />
      </button>
      <button
        title="Delete Frame"
        onClick={() => { 
          deleteFrame()
          // if (VIEW.frameMode) {
          //   deleteWholeFrame()
          // } else {
          //   deleteFrame()
          // }
        }}
        class='w-30 fl fl-center'>
        <img src={`img/delete.svg`} />
      </button>
      <button
        title="Split Clip"
        onClick={() => { splitActiveClip() }}
        class="fl fl-center m-0 p-0 w-30 bord-dark-l hidden">
        <img src="img/split.svg" />
      </button>
    </div>
  )
}

const AnimControls = () => {
  return (
    <div class='fl'>
      <button
        id="anim-left"
        title="Onion Skin"
        onClick={() => { VIEW.onionSkinning = !VIEW.onionSkinning; VIEW.render() }}
        class="fl fl-center m-0 p-0 w-30 bord-dark-r"
        style={VIEW.onionSkinning ? 'background: rgba(52, 152, 219, 255);' : ''}>
        <img src="img/onion.svg" />
      </button>
      <button
        title="Last Frame"
        onClick={() => { lastFrame() }}
        class="fl fl-center m-0 p-0 w-30 bord-dark-r" >
        <img src="img/lastframe.svg" />
      </button>
      <button
        id="play-button"
        title="Play Animation"
        onClick={() => { togglePlay() }}
        class="fl fl-center m-0 p-0 w-30 bord-dark-r" >
        <img src={`img/${VIEW.isPlaying ? 'stop.svg' : 'play.svg'}`} />
      </button>
      <button
        id="anim-right"
        title="Next Frame"
        onClick={() => { nextFrame() }}
        class="fl fl-center m-0 p-0 w-30" >
        <img src="img/nextframe.svg" />
      </button>
      
    </div>
  )
}

const FPS = () => {
  return (
    <div title="Frames Per Scond" class='fl fl-center' style="padding: 2px 2px; height: 100%; gap: 6px">
      <div class="select" style="height: 100%;">
        <select
          onInput={(e) => {
            APP.fps = parseInt(e.target.value)
          }}
          style="padding: 0px; padding-right: 16px; padding-left: 5px;"
          value={APP.fps}
          class="h-full">
            {
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((size, i) => {
                return <option value={i}>{size}</option>
              })
            }
        </select>
      </div>
      <small>FPS</small>
    </div>
  )
}

export const Timeline= () => {
  return (
    <div class='bg-light bord-dark-t fl-column relative' style='height: 200px;'>
      <div class='bg-light fl w-full bord-dark-b h-30 show-at-850'>
        <div class='fl fl-1 bord-dark-r'>
          <FPS />
        </div>
        <div class='fl'>
          <AnimControls />
          {/* <div class='fl'>
            <button
              title="Onion Skin"
              onClick={() => { VIEW.onionSkinning = !VIEW.onionSkinning; VIEW.render() }}
              class="fl fl-center m-0 p-0 w-30 bord-dark-r"
              style={VIEW.onionSkinning ? 'background: rgba(52, 152, 219, 255);' : ''}>
              <img src="img/onion.svg" />
            </button>
            <button
              title="Last Frame"
              onClick={() => { lastFrame() }}
              class="fl fl-center m-0 p-0 w-30 bord-dark-r" >
              <img src="img/lastframe.svg" />
            </button>
            <button
              title="Play Animation"
              onClick={() => { togglePlay() }}
              class="fl fl-center m-0 p-0 w-30 bord-dark-r" >
              <img src={`img/${VIEW.isPlaying ? 'stop.svg' : 'play.svg'}`} />
            </button>
            <div class="select">
              <select
                onInput={(e) => {
                  VIEW.brushSize = parseInt(e.target.value)
                }}
                style="padding: 0px; padding-right: 18px; padding-left: 5px;"
                value={VIEW.brushSize}
                class="h-full">
                  {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((size, i) => {
                      return <option value={i}>{size}</option>
                    })
                  }
              </select>
            </div>
            <button
              title="Next Frame"
              onClick={() => { nextFrame() }}
              class="fl fl-center m-0 p-0 w-30 bord-dark-r" >
              <img src="img/nextframe.svg" />
            </button>
          </div> */}
        </div>
      </div>
      <div class='fl w-full bord-dark-b h-30'>
        <div class='fl fl-justify-between bord-dark-r' style='width: 120px;'>
          {/* <div style='width: 90px;' class='p-h-10 fl fl-center-y bord-dark-r'>
            <small><b>Layers</b></small>
          </div> */}
          <div class='fl'>
            <button
              title="Add Layer"
              onClick={() => { insertLayer() }}
              class='w-30 fl fl-center bord-dark-r' >
              <img src={`img/insert.svg`} />
            </button>
            {/* <button
              title="Clone Layer"
              onClick={() => { cloneLayer() }}
              class='w-30 fl fl-center bord-dark-r' >
              <img src={`img/clone.svg`} />
            </button> */}
            <button
              title="Move Layer Up"
              onClick={() => { moveLayerUp() }}
              class='w-30 fl fl-center bord-dark-r'>
              <img src={`img/up.svg`} />
            </button>
            <button
              title="Move Layer Down"
              onClick={() => { moveLayerDown() }}
              class='w-30 fl fl-center bord-dark-r'>
              <img src={`img/down.svg`} />
            </button>
            <button
              title="Delete Layer"
              onClick={() => { deleteLayer() }}
              class='w-30 fl fl-center bord-dark-r'>
              <img src={`img/delete.svg`} />
            </button>
          </div>
        </div>
        <div class='fl fl-1'>
          <div class='fl hide-at-850'>
            <FPS />
          </div>
          <div class='fl-1 fl fl-justify-center  hide-at-850'>
            <AnimControls />
          </div>
          <div class="fl-1 show-at-850"></div>
          <div class='fl'>
            
            <FrameOps />
          </div>
        </div>
      </div>
      <div id="layers-height" class='fl bg-mid'>
        <div id='layers' class='overflow hide-scroll' style='padding-bottom: 30px;'>
          {VIEW.onionSkinning && (
            <div class="bord-dark-r bord-dark-b" style={{ height: '15px' }}>
              <div class='w-30 h-full fl fl-center'>
                <img style="width: 10px;" src="img/onion.svg" />
              </div>
            </div>
          )}
          <div class='bord-dark-r fl-col-reverse' style='width: 120px;'>
            {
              APP.layers.map((layer, li) => {
                return <div
                  class='fl bord-dark-b h-30'
                  style={`background: ${!VIEW.multiSelectMode && APP.layerActive === li ? 'rgb(100, 100, 100)' :''};`}>
                  <button
                    style={`${layer.hidden ? 'background: rgba(52, 152, 219, 255);' : ''}`}
                    onClick={() => { layersEditHidden(li) }}
                    class='w-30 h-30 fl fl-center bord-dark-b'>
                    <img src={`img/${layer.hidden ? 'eye-active.svg' : 'eye.svg'}`} />
                  </button>
                  <button
                    onClick={() => { setTargetCanvas(APP.frameActive, li) }}
                    class='fl-1 txt-left'>
                    <small style='font-size: 11px;'><b>{layer.name}</b></small>
                  </button>
                </div>
              })
            }
          </div>
        </div>
        <div id='frames' class='fl-1 overflow hide-scroll' style='padding-bottom: 30px;'>
          {VIEW.onionSkinning && (
            <div class='bord-dark-b fl' style={{ height: '15px', minWidth: 'max-content'}}>
            {
              APP.layers[0].clips.map((frame, i) => {
                const activeFrame = APP.frameActive === i;
                const isWithinRange =
                  i >= APP.frameActive - APP.onionSkin.framesBehind &&
                  i <= APP.frameActive + APP.onionSkin.framesAhead;
                const isWithinMax = 
                  i >= APP.frameActive - 5 &&
                  i <= APP.frameActive + 5;

                let bgColor = 'bg-xlight'
                if (isWithinRange) bgColor = 'bg-blue'
                // if (activeFrame) bgColor = 'bg-blue'
                // if (!activeFrame && isWithinRange) bgColor = 'bg-xlight'
                
                if (isWithinMax) {
                  return (
                    <button
                      onClick={() => { handleOnionSkin(i) }}
                      style="width:30px"
                      class={`no-hover bord-dark-r ${bgColor}`}
                    >
                      {activeFrame && <img style="width: 10px;" src="img/onion.svg" />}
                    </button>
                  );
                } else {
                  return <div style="width: 30px;"></div>
                }
                
              })                
            }
            </div>
          )}
          <canvas
            id='timeline-canvas'
            style='cursor: pointer;'
            onClick={(e) => {
              const x = Math.floor(e.offsetX / 30)
              const y = (APP.layerCount - 1) - Math.floor(e.offsetY / 30)

              if (VIEW.multiSelectMode) {
                const clip = APP.layers[y].clips[x]

                if (clip !== 0) {
                  VIEW.multiSelected.has(clip) ?
                    VIEW.multiSelected.delete(clip) :
                    VIEW.multiSelected.add(clip);
                  VIEW.render()
                }
                
                // // Check if the selected item is already in the list
                // const existingIndex = VIEW.multiSelected.findIndex(item => item[0] === x && item[1] === y);
                // const multiSelectPrev = JSON.parse(JSON.stringify(VIEW.multiSelected));

                // if (existingIndex !== -1) {
                //   VIEW.multiSelected.splice(existingIndex, 1); // If it exists, remove it
                // } else {
                //   VIEW.multiSelected.push([x, y]); // Otherwise, add it to the selection
                // }

                // VIEW.multiSelected.sort((a, b) => a[0] - b[0] || a[1] - b[1]); // Sort by frame index first, then by layer index
                // const multiSelectNext = JSON.parse(JSON.stringify(VIEW.multiSelected));

                // addToUndo('MultiSelect Selected')
                // setUndoRedo(
                //   () => {
                //     VIEW.multiSelected = multiSelectPrev
                //   },
                //   () => {
                //     VIEW.multiSelected = multiSelectNext
                //   }
                // )

                // VIEW.render();
              } else {
                setTargetCanvas(x, y)
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}